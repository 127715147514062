// メタ情報の基本スタイル
.c-meta {
  // 日付
  &-date {
    @include font-size(1.2rem);
    letter-spacing: .1em;
    color: $text-color-sub;
    font-family: $web-font;
  }
  // カテゴリー
  &-category {
    @include font-size(1.2rem);
    a {
      text-decoration: none;
      color: $primary !important;
    }
  }
}