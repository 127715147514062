/*
* loading Settings
*/

/* Loading Block */
#loading {
	width: 100vw;
	height: 100vh;
	transition: all 1s;
	background-color: $loding-bg;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
}

.spinner {
	width: 100px;
	height: 100px;
	margin: calc(50vh - 100px) auto;
	background-color: $loding-spinner;
	border-radius: 100%;
	animation: sk-scaleout 1.3s infinite ease-in-out;
}

/* Loading Animation */
@keyframes sk-scaleout {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1.0);
		opacity: 0;
	}
}

/* Hide Loading Block */
.loaded {
	opacity: 0;
	visibility: hidden;
}


// /*========= LoadingのためのCSS ===============*/

// /* Loading背景画面設定　*/
// #splash {
//   /*fixedで全面に固定*/
// 	position: fixed;
// 	width: 100%;
// 	height: 100%;
// 	z-index: 999;
// 	background:$primary;
// 	text-align:center;
// 	color:#fff;
// }

// /* Loading画像中央配置　*/
// #splash_logo {
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	transform: translate(-50%, -50%);
// }

// /* Loading アイコンの大きさ設定　*/
// #splash_logo svg{
//   width:350px;
// }

// /*=============== SVGアニメーション内の指定 =================*/

// /*アニメーション前の指定*/
// #mask path{
//   fill-opacity: 0;/*最初は透過0で見えない状態*/
//   transition: fill-opacity .5s;/*カラーがつく際のアニメーション0.5秒で変化*/
//   fill: none;/*塗りがない状態*/
//   stroke: $text-color;/*線の色*/
//   stroke-width: 1px;
// }

// /*アニメーション後に.doneというクラス名がで付与された時の指定*/
// #mask.done path{
//   fill: $text-color;/*塗りの色*/
//   fill-opacity: 1;/*透過1で見える状態*/
//   stroke: none;/*線の色なし*/
// }


// /*========= 画面遷移のためのCSS ===============*/

// /*画面遷移アニメーション*/
// .splashbg{
//   display: none;
// }

// /*bodyにappearクラスがついたら出現*/
// body.appear .splashbg{
//   display: block;
//   content: "";
//   position:fixed;
//   z-index: 999;
//   width: 100%;
//   height: 100vh;
//   top: 0;
//   left: 0;
//   transform: scaleY(0);
//   background-color: $primary;/*伸びる背景色の設定*/
//   animation-name:PageAnime;
//   animation-duration:1.2s;
//   animation-timing-function:ease-in-out;
//   animation-fill-mode:forwards;
// }

// @keyframes PageAnime{
// 0% {
//   transform-origin:top;
//   transform:scaleY(0);
// }
// 50% {
//   transform-origin:top;
//   transform:scaleY(1);
// }
// 50.001% {
//   transform-origin:bottom;
// }
// 100% {
//   transform-origin:bottom;
//   transform:scaleY(0);
// }
// }

// /*画面遷移の後現れるコンテンツ設定*/
// #page{
//   opacity: 0;/*はじめは透過0に*/
// }

// /*bodyにappearクラスがついたら出現*/
// body.appear #page{
//   animation-name:PageAnimeAppear;
//   animation-duration:1s;
//   animation-delay: 0.8s;
//   animation-fill-mode:forwards;
//   opacity: 0;
// }

// @keyframes PageAnimeAppear{
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }
