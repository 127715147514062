$position:
	right,
	center,
	left;

.u-text {
	@each $value in $position {
		&--#{$value} {
			text-align: #{$value} !important;
		}
	}

	&--left {
		@each $breakpoint, $width in $grid-breakpoints {
			@include mq-down($breakpoint) {
				&--#{$breakpoint} {
					text-align: left !important;
				}
			}
		}
	}
}

