/*!------------------------------------*\
    Top
\*!------------------------------------*/
.js-gnav-nav {
	&--fix {
		@include mq-down($gnav-breakpoint) {
			top: 0;
			right: -100%;
			max-height: $gnav-top-nav-maxHeight;
			transition: right 0.3s $gnav-transitionFunction;
			width: calc(100% - 45px);
			height: 100%;

			// アクティブ時
			&.is-active {
				overflow: visible;
				opacity: 1;
				right: 0;

				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					pointer-events: none;
					//border-left: 45px solid rgba($color: #000000, $alpha: 0.2);
				}
			}
		}
	}
}
