// 見出し系の装飾
// .is-style-XX はエディター用

.u-title {

	//テキストの行数
	&--hidden {
		&-01 {
			margin-bottom: 0;
			width: 100%;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		&-02 {
			* {
				margin-bottom: 0;
				width: 100%;
				display: -webkit-box;
				overflow: hidden;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}

		&-03 {
			* {
				margin-bottom: 0;
				width: 100%;
				display: -webkit-box;
				overflow: hidden;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
			}
		}
	}

	&--first-color {
		.first-letter {
			color: $primary; //非対応のブラウザでの文字色を設定
			background: -webkit-linear-gradient(0deg, #5ebdd5, #52c1a2); //背景色にグラデーションを指定
			-webkit-background-clip: text; //テキストでくり抜く
			-webkit-text-fill-color: transparent; //くり抜いた部分は背景を表示
		}
	}

	&--text-gradation {
		display: inline;
		color: $primary; //非対応のブラウザでの文字色を設定
		background: -webkit-linear-gradient(0deg, #5ebdd5, #52c1a2); //背景色にグラデーションを指定
		-webkit-background-clip: text; //テキストでくり抜く
		-webkit-text-fill-color: transparent; //くり抜いた部分は背景を表示
	}


}
