.u-position {
	&--center {
    margin: auto auto;
  }
  &--left {
    margin-right: auto;
  }
  &--right {
    margin-left: auto;
  }
}

