// エントリー要素レイアウト

.c-entry {
  // 投稿ポストのヘッダー要素
  &-header {

  }

  // 固定ページエントリーヘッダー
  &-pageheader {
    padding: 1em 0;
    &__title {
    }
  }

  // コンテンツ要素
  &-content {
    margin-bottom:5rem;
  }
}

.c-entry-content{
  margin-bottom:5rem;
}