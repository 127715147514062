.c-comman__comparison-table__01 {
    table {
        width: 100%;
        margin-bottom: 100px;

        tr {
            font-size: 1.8rem;

            @include mq-down(md) {
                font-size: 1.6rem;
                margin-bottom: 5rem;
                height: 30px !important;
            }

            td {
                padding: 1.5rem 0;
                border-top: 1px solid #CDD6DD;
                border-bottom: 1px solid #CDD6DD;

                @include mq-down(md) {
                    margin-bottom: 50px;
                    height: 30px !important;
                }
            }

            td:nth-of-type(1) {
                background-color: #F0F0E8;
                padding-left: 100px;
                width: 300px !important;

                @include mq-down(lg) {
                    padding-left: 30px;
                }

                @include mq-down(md) {
                    width: fit-content;
                    padding: 1rem 1.5rem;
                }
            }

            td:nth-of-type(2),
            td:nth-of-type(3),
            td:nth-of-type(4),
            td:nth-of-type(5) {
                width: 200px !important;
            }
        }

        tr td+td {
            padding: 13px 30px 13px 30px;
            white-space: nowrap;
            text-align: center;

            @include mq-down(md) {
                padding: 10px;
                white-space: normal;
            }
        }

        tr:nth-child(1) td:nth-child(1) {
            background: none;
        }

        tr:nth-child(1) {
            height: 37px !important;
        }

        tr:nth-child(1) td {
            border-top: none;
            height: 37px !important;
        }

        tr td:nth-of-type(1) {
            border: none;
        }

    }
}