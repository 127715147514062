/*!------------------------------------*\
    Right
\*!------------------------------------*/
.js-gnav-nav {
	&--right {
		@include mq-down( $gnav-breakpoint ) {
			right: (0 - $gnav-width) !important;
			transition: right $gnav-transitionDuration $gnav-transitionFunction;

			// アクティブ時
			&.is-active {
				right: 0 !important;
				a {
					align-items: flex-start;
				}
			}
		}
	}
}
