$form__dot-color: #3B4043;
$form__line-color: #3B4043;
$form__input-color: #959EA7;
$form__border-color: #707070;

/*--------------------------------------------
フォームのステータス
--------------------------------------------*/
/*入力状況*/
.form-status {
	width: 24rem;
	margin: -2rem auto 8rem;
	position: relative;

	ul {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;
		position: relative;
		padding: 0;
		margin-top:10rem;
		li {
			list-style: none;
			position: relative;
			display: inline-block;

			&:before {
				content: "";
				width: 8px;
				height: 8px;
				display: block;
				background-color: $form__input-color;
				border-radius: 50%;
				position: relative;
				z-index: 1;
			}
			.text {
				text-align: center;
				position: absolute;
				top: 2rem;
				left: 0;
				right: 0;
				margin: auto -2em;
				font-size: 14px;
			}
		}
	}
}

.contact-line {
	position: absolute;
	top: 50%;
	left: 0;
	width: 99%;
	height: 1px;
	border-top: 1px solid $form__input-color;
	transform: translateY(-50%);
}

.mw_wp_form_input .form-status li:nth-child(1) {
	//color: $primary;

	&:before {
		background-color: $form__dot-color;
	}

	&:after {
		content: "";
		background: $white;
		border: 1px solid $form__dot-color;
		border-radius: 50%;
		width: 16px;
		height: 16px;
		display: block;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.mw_wp_form_confirm .form-status li:nth-child(2) {
	//color: $primary;

	&:before {
		background-color: $form__dot-color;
	}

	&:after {
		content: "";
		background: $white;
		border: 1px solid $form__dot-color;
		border-radius: 50%;
		width: 16px;
		height: 16px;
		display: block;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.mw_wp_form_complete .form-status li:nth-child(3) {
	//color: $white;

	&:before {
		background-color: $form__dot-color;
	}

	&:after {
		content: "";
		background: $white;
		border: 1px solid $form__dot-color;
		border-radius: 50%;
		width: 16px;
		height: 16px;
		display: block;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

@media screen and (max-width: 767px) {
	.form-status {
		margin: -2rem auto 6rem;
	}
}

/*
* contact Style
*/

//エラー
.mw_wp_form .error {
	font-size: 12px;
	margin-top: 4px;
	font-weight: 400 !important;
}

.form-outer-field {

	// 基本設定
	// =================================
	//入力エリアのサイズ
	.input-box {
		height: 50px;
		border: 1px solid $form__input-color;
		background: #f8f8f8;
		padding: 12px 12px;

		@include mq-down(md) {
			padding: 8px 8px;
		}

		&--l {
			width: 100%;
		}

		&::placeholder {
			font-size: 12px;
			color: $form__input-color;

			@include mq-down(md) {
				font-size: 14px;
			}
		}

		&--ss {
			width: 80px;
		}

		&--s {
			width: 250px;

			@include mq-down(md) {
				width: 80px;
			}
		}

		&--m {
			width: 344px;

			@include mq-down(md) {
				width: 100%;
			}
		}

		&--textarea {
			height: 225px;
		}
	}

	.form-wrap {
		width: 100%;

		select {
			font-size: 14px;
		}
	}

	//項目ごと
	.form-group {
		padding: 20px 0;
		border-bottom: 1px solid $form__border-color;

		&--first {
			border-top: 1px solid $form__border-color;
		}
	}

	//ラベルデザイン
	.form-label {
		font-weight: 700;
		display: flex;
		align-items: flex-start;
		font-size: 16px;
		padding-top: 4px;

		@include mq-down(md) {
			align-items: center;
			font-size: 14px;
		}

		&__title {
			width: 100%;
			display: flex;
			align-items: flex-start;
			justify-content: space-between;


			@include mq-down(md) {
				width: 100%;
				margin: 0 0 12px;
				justify-content: flex-start;
			}

			&__head {
				margin-bottom: 0;
			}
		}
	}

	//注意書き
	.input-caution {
		font-size: 12px;
		margin: 4px 0 0;
	}

	//郵便番号検索
	.zip_1 {
		display: flex;
		align-items: center;
	}

	.zip-link {
		font-size: 16px;
		font-weight: 700;

		margin-left: 20px;
		color: $text-color;

		@include mq-down(md) {
			font-size: 14px;
		}

		&:hover {
			text-decoration: underline;
		}

		&:before {
			font-family: "Font Awesome 5 Free";
			content: "\f105";
			font-weight: 900;
			display: inline-block;
			color: $text-color;
		}
	}

	//チェックボックス
	.check-box {
		display: flex;
		align-items: center;
		justify-content: center;

		&--field {
			text-align: center;
			margin: 40px 0;

			&__link {
				color: $text-color;
				font-weight: 700;
				margin: 0 0 20px;
				display: block;

				@include mq-down(md) {
					font-size: 12px;
				}

				&:hover {
					text-decoration: underline;
				}

				&:before {
					font-family: "Font Awesome 5 Free";
					content: "\f105";
					font-weight: 900;
					display: inline-block;
					color: $text-color;
				}
			}
		}

		input[type=checkbox] {
			appearance: none;
			display: none;
		}

		input[type=checkbox]+span:before {
			font-family: "Font Awesome 5 Free";
			content: "\f0c8";
			display: inline-block;
			margin-right: 0.5em;
			font-weight: 400;
			font-size: 24px;
		}

		input[type=checkbox]:checked+span:before {
			content: "\f14a";
			font-weight: 400;
		}
	}

	//必須
	.hissu {
		padding: 2px 8px;
		border-radius: 4px;
		background: red;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		color: $white;
		font-family: $web-font;
		font-size: 10px;
		margin: 4px 0 0;

		@include mq-down(md) {
			margin: 0 0 0 8px;
		}
	}

	//プライバシーポリシー
	.privacy-link {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 40px;

		p {
			color: $text-color;
			font-weight: bold;
			font-size: 16px;
			margin-bottom: 0;
		}

		a {
			text-decoration: underline;
			color: $text-color;
			margin: 0 4px;
		}
	}

	//リキャプチャ
	.recaptcha_policy {
		padding: 0;
		margin: 40px 0 0;
		text-align: center;
		font-size: 11px !important;
		color: $text-color  !important;
	}

	.recaptcha_policy a {
		font-size: 11px !important;
		color: $form__input-color  !important;
	}

	//ボタンエリア
	.send-btn-field {
		margin-top: 40px;
	}
}

//確認画面時は見せない要素
.mw_wp_form_confirm,
.mw_wp_form_preview {
	.confilm-none {
		display: none;
	}
}


.mw_wp_form_input{
	margin-top:50px;
}