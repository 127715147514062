.c-imgtext1 {
	//--------------------------------------------------
	&__wrap {
		@include mq-down(md) {
			width: 100%;
			margin: 0;
		}
	}

	//--------------------------------------------------
	&__info {
		width: 35%;
		@include mq-down(xl) {
			width: 46%;
		}
		@include mq-down(md) {
			width: 100%;
			padding: 0;
		}
	}
	//--------------------------------------------------
	&__ttl {
		font-size: 0;
		margin: 0 0 60px;
		@include mq-down(md) {
			margin: 0 0 55px;
		}

		&en {
			display: inline-block;
			@include font-size(1.8rem);
			color: #373737;
			font-weight: normal;
			letter-spacing: 0.9px;
			@include poppins();
			position: relative;
			padding: 0 89px 0 0;
			@include mq-down(md) {
				font-size: 1.4rem;
				letter-spacing: 0.7px;
			}

			&::after {
				content: "";
				width: 60px;
				height: 1px;
				background: #717171;
				position: absolute;
				top: 49%;
				right: 0;
			}
		}

		&jp {
			display: block;
			margin: 56px 0 0;
			@include mq-down(md) {
				margin: 58px 0 0;
			}
		}
	}

	//--------------------------------------------------
	&__txt {
		@include font-size(1.8rem);
		line-height: 1.8;
		color: #1c1c1c;
		letter-spacing: 0.1em;
		margin: 0;
		@include mq-down(md) {
			font-size: 1.4rem;
			letter-spacing: 0;
		}

		&:not(:last-child) {
			margin: 0 0 22px;
			@include mq-down(md) {
				margin: 0 0 26px;
			}
		}
	}

	//--------------------------------------------------
	&__imgbox {
		position: relative;
		width: 53%;
		margin: 53px 0 0;
		@include mq-down(md) {
			width: 100%;
			padding: 0;
			margin: 60px 0 0;
		}

		img {
			width: 100%;
		}
	}

	//--------------------------------------------------
	&__img1 {
		padding: 0 23px 0 3px;
	}

	//--------------------------------------------------
	&__img2 {
		width: 29%;
		margin: 0 0 0 auto;
		@include mq-down(md) {
			width: 40%;
		}
	}

	.c-btn1 {
		margin: 64px 0 0;
		@include mq-down(md) {
			margin: 80px 0 0;
		}
	}
}

.c-imgText2 {
	position: absolute;
	pointer-events: none;
	max-width: 100%;
	z-index: 0;

	@include mq-down(md) {
		width: 100%;
		padding: 0 10px;

		img {
			width: 100%;
		}
	}
}
