// 見出し系の装飾
// .is-style-XX はエディター用

.c-title,
.is-style-h {
	&__01 {
		text-align: center;

		&__icon {
			margin-bottom: 20px;

			.icon {
				width: 29px;
				height: 38px;
			}
		}

		&__title {
			font-size: 2rem;
			font-weight: 500;
			letter-spacing: 0.1em;

			span {
				font-family: $web-font;
				font-size: 4rem;
				font-weight: 700;
				display: block;
				letter-spacing: 0.1em;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-title1 {
	&__en {
		display: block;
		@include font-size(1.8rem);
		line-height: 1.5;
		color: #c62828;
		font-weight: normal;
		letter-spacing: 0.9px;
		text-transform: uppercase;
		@include poppins();
		@include mq-down(md) {
			font-size: 1.2rem;
			letter-spacing: 0.6px;
		}
	}

	&__jp {
		display: block;
		@include font-size(4.2rem);
		line-height: 1.35;
		color: #1c1c1c;
		font-weight: bold;
		margin: 11px 0 0;
		@include mq-down(md) {
			font-size: 2.6rem;
			line-height: 1.6;
			letter-spacing: 1.3px;
			margin: 10px 0 0;
		}

		&.is-sub {
			display: flex;
			align-items: flex-end;
			flex-shrink: 0;
			white-space: nowrap;
			@include mq-down(md) {
				flex-direction: column;
				align-items: flex-start;
			}
		}

		sub {
			position: relative;
			@include padding-left(8rem);
			@include font-size(1.6rem);
			white-space: normal;
			line-height: 1.75;
			font-weight: 400;
			flex-shrink: 1;
			top: -2px;
			@include mq-down(md) {
				margin-top: 29px;
				padding-left: 0 !important;
				@include font-size(1.2rem);
				line-height: 2.1;
			}
		}
	}

	&--center {
		text-align: center;
	}
}

/*
------------------------------------------------------------*/
.c-title2 {
	text-align: center;
	@include font-size(1.8rem);
	line-height: 1.5;
	color: #c62828;
	letter-spacing: 0.9px;
	@include poppins();
	@include mq-down(md) {
		font-size: 1.6rem;
		letter-spacing: 0.8px;
	}
}

/*
------------------------------------------------------------*/
.c-title3 {
	letter-spacing: 0;

	&__en {
		display: inline-block;
		@include font-size(1.8rem);
		line-height: 1.5;
		color: #505050;
		font-weight: normal;
		text-transform: uppercase;
		@include poppins();
		position: relative;
		left: 1px;
		padding: 0 90px 0 0;
		@include mq-down(md) {
			font-size: 1.4rem;
			line-height: 1.5;
			color: #373737;
			letter-spacing: 0.7px;
			padding: 0 89px 0 0;
		}

		&::after {
			content: "";
			width: 60px;
			height: 1px;
			background: #464646;
			position: absolute;
			top: 52%;
			right: 0;
			@include mq-down(md) {
				background: #717171;
				top: 46%;
			}
		}
	}

	&__jp {
		display: block;
		@include font-size(4.8rem);
		line-height: 1.35;
		color: #1c1c1c;
		margin: 15px 0 0;
		@include mq-down(md) {
			font-size: 2.6rem;
			margin: 21px 0 0;
		}
	}
}

.c-title4 {
	width: fit-content;
	margin:0 auto;
	h2{
		font-weight: bold;
	}
	.en{
		text-align: left;
		display: block;
		color:#505050;
		position: relative;
		&:after{
			content:"";
			display:block;
			width:60px;
			height:1px;
			background-color:#464646;
			position:absolute;
			bottom:12px;
			left:160px;
			@include mq-down(md) {
				left:140px;
				bottom:11px;
			}
		}

	}
	.red{
		color:#CA4444;
	}
}
