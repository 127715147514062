.c-single-work__thumbnail {
	width: 100%;

	img {
		width: 100%;
		aspect-ratio: 11 / 6;
		object-fit: cover;
		object-position: top;
	}
}

.c-single-work__textbox__contents {
	.col-lg-5 {
		margin-left: auto;
	}

	.c-single-work__textbox {
		background-color: white;
		padding: 5rem;
		font-size: 2.2rem;
		position: relative;
		bottom: 20rem;
		margin-bottom: -20rem;
		line-height: 4.5rem;
		@include contents-box-shadow();
		@include mq-down(lg) {
			font-size: 1.6rem;
			bottom: 0;
			margin-top: 25px;
			padding: 20px;
			margin-bottom: 0;
		}
	}
}

.c-single-work__contents__info {
	margin-top: 90px;
	margin-bottom: 150px;

	.c-single-work__contents__info__item {
		margin-top: 120px;

		h2 {
			@include main-headline();
		}

		p {
			padding-top: 30px;
			@include sub-headline();
		}

		.c-single-work__contents__info__item__img {
			width: 80%;
			margin-left: auto;
			img {
				width: 100%;
				aspect-ratio: 11 / 5;
				object-fit: cover;
				// object-position: top;
			}
		}
	}
}

.c-single-work__contents__other{
	background-color:$color4;
	h2{
		@include main-headline();
		position:relative;
		bottom:3.5rem;
		@include mq-down(md) {
			bottom:2.0rem;
		}
	}
}