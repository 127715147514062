// フロントページ

.p-front {
	&-fv {
		position: relative;

		&__slider {
			position: relative;
			height: 100vh;
			min-height: 500px;
			overflow: hidden;
			z-index: 1;

			&__inner {
				height: 100%;
			}

			&__item {
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
		&__body {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-bottom: 97px;
		}

		&__img1 {
			padding: 40px;
			max-width:835px;
			@include mq-down(md) {
				width: 85%;
				padding: 0;
			}

			img {
				@include mq-down(md) {
					width: 100%;
				}
			}
		}

		&__scroll {
			font-size: 1.4rem;
			line-height: 1;
			color: #ffffff !important;
			font-weight: normal;
			@include poppins();
			padding: 0 0 99px;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 35px;
			height: 97px;
			z-index: 3;

			&::before {
				content: "";
				width: 1px;
				height: 7.5px;
				background: #fff;
				position: absolute;
				left: 50%;
				top: 39px;
				transform: translate(-50%, 0);
				animation: pathmove 2s ease-in-out infinite;
			}

			&::after {
				content: "";
				width: 1px;
				height: 60px;
				background: rgba($color: #ffffff, $alpha: 0.5);
				position: absolute;
				left: 50%;
				bottom: 0;
				transform: translate(-50%, 0);
			}
		}
	}
	@keyframes pathmove {
		0% {
			height: 7.5px;
			top: 39;
			opacity: 0;
		}
		30% {
			height: 60px;
			opacity: 1;
		}
		100% {
			height: 0;
			top: 120px;
			opacity: 0;
		}
	}
	&-sc1 {
		padding-top: 112px;
		@include mq-down(md) {
			padding-top: 60px;
		}

		.c-imgtext1 {
			&__img2 {
				margin-bottom: -100px;
				@include mq-down(md) {
					margin-bottom: -120px;
					position: relative;
					z-index: 1;
				}
			}
			&__ttljp{
				@include mq-down(md) {
					max-width: 250px;
					img{
						width:100%;
					}
				}
				
			}
		}
	}

	&-sc2 {
		position: relative;
		margin-top: -7px;

		.c-line1 {
			@include mq-down(md) {
				margin: 10px 0 -1px;
			}
		}

		&__inner {
			background-color: $color3;
			padding-top: 6px;
			padding-bottom: 100px;
			@include mq-down(md) {
				padding-top: 48px;
				padding-bottom: 74px;
			}

			.l-container {
				position: relative;
				z-index: 1;
			}
		}
		.u-bg__color4{
			background-color: $color4;
		}

		&__heading {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
			justify-content: space-between;
			margin-bottom: 54px;
			@include mq-down(md) {
				display: block;
				margin-bottom: 45px;
			}
		}

		&__txt {
			flex: 1;
			margin: 0 0 0 80px;
			position: relative;
			@include font-size(1.6rem);
			white-space: normal;
			line-height: 1.75;
			top: -5px;
			@include mq-down(md) {
				flex: none;
				margin: 33px 0 0;
				@include font-size(1.2rem);
				line-height: 2.1;
			}
		}

		.c-btn2 {
			margin-top: 59px;
			@include mq-down(md) {
				margin-top: 68px;
			}
		}

		.c-imgText2 {
			right: 0;
			bottom: -1px;
			@include mq-down(md) {
				bottom: 40px;
			}
		}
	}

	&-sc3 {
		position: relative;
		padding-top: 152px;
		padding-bottom: 143px;
		@include mq-down(md) {
			padding-top: 61px;
			padding-bottom: 91px;
		}

		.l-container {
			position: relative;
			z-index: 1;
		}

		.c-title1 {
			margin-bottom: 48px;
			@include mq-down(md) {
				margin-bottom: 30px;
			}
		}

		.c-text1 {
			margin-bottom: 34px;
			@include mq-down(md) {
				margin-bottom: 36px;
			}
		}

		.c-imgText2 {
			left: 0;
			top: 219px;
			@include mq-down(md) {
				display: none;
			}
		}

		.c-list2 {
			margin-bottom: 80px;
			@include mq-down(md) {
				margin-bottom: 60px;
			}
		}

		.c-title2 {
			margin-bottom: 39px;
			@include mq-down(md) {
				margin-bottom: 22px;
			}
		}
	}

	&-sc4 {
		position: relative;
		padding-top: 96px;
		padding-bottom: 140px;
		@include mq-down(md) {
			padding-top: 36px;
			padding-bottom: 125px;
		}

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 77%;
			height: 549px;
			background-color: #f2f2f2;
			pointer-events: none;
			z-index: -1;
			@include mq-down(md) {
				height: 221px;
				width: 78.717%;
			}
		}

		.c-title3 {
			margin-bottom: 50px;
			@include mq-down(md) {
				margin-bottom: 38px;
			}
		}

		.c-slide1 {
			margin-bottom: 66px;
			@include mq-down(md) {
				margin-bottom: 40px;
			}
		}
	}

	&-sc5 {
		padding-bottom: 200px;
		@include mq-down(md) {
			padding-bottom: 112px;
		}
	}

	&-sc6 {
		padding-bottom: 134px;
		@include mq-down(md) {
			padding-bottom: 91px;
		}

		.c-heading {
			margin-bottom: 117px;
			@include mq-down(md) {
				margin-bottom: 35px;
			}
		}
	}
}
