.c-card {
	overflow: hidden;
	a{
		// display: block;
	}

	// ボディエリア
	&__body {
		padding: 40px 20px;

		@include mq-down(sm) {
			padding: 20px 20px;
		}
	}

	&--round {
		border-radius: 10px;
		margin-bottom:20px;
	}
}

.column__img--sp{
	width:100px;
	height: 100px;
	img{
		width:100%!important;
		height: 100px;
		aspect-ratio:1 / 1!important;
		border-radius: 7px;
	}
}
