/*
* General Settings
*/
:root {
	scroll-behavior: initial !important;
}

html {
	scroll-behavior: initial !important;
	margin-top: 0 !important;
}

body {
	@include noto-sans();
	-webkit-font-smoothing: antialiased;
	font-feature-settings: "palt";
	overflow-x: hidden;
	overflow-y: auto;
	color: #1c1c1c;

	&.is-resizing * {
		transition: none !important;
	}

	&.body-fixed {
		overflow: hidden !important;
	}
}

a {
	transition: 0.3s ease-in-out;
	text-decoration: none;

	@include hover {
		opacity: 0.75;
	}
}

nav {
	ol,
	ul,
	li {
		list-style: none;
		padding: 0;
		margin: 0;
	}
}

p {
	margin: 0;
}

img {
	max-width: 100%;
	height: auto;
}

svg {
	width: 1em;
	height: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

[data-scroll] {
	/* width */
	&::-webkit-scrollbar {
		width: 8px;
		@include mq-down(md) {
			width: 4px;
		}
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: transparent;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #888;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}
