// メディアクエリ
@function breakpoint-min2($name, $breakpoints: $grid-breakpoints) {
	$min: map-get($breakpoints, $name);
	@return if($min and $min > 0, $min, null);
}

@function breakpoint-max2($name, $breakpoints: $grid-breakpoints) {
	$max: map-get($breakpoints, $name);
	@return if($max !=0, $max - 1px, null);
}

@mixin mq-up($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min2($name, $breakpoints);

	@if $min {
		@media (min-width: $min) {
			@content;
		}
	}

	@else {
		@content;
	}
}

@mixin mq-down($name, $breakpoints: $grid-breakpoints) {
	$max: breakpoint-max2($name, $breakpoints);

	@if $max {
		@media (max-width: $max) {
			@content;
		}
	}

	@else {
		@content;
	}
}

// ie11ハック用
@mixin ie11() {

	@at-root _:-ms-fullscreen,
	:root & {
		@content;
	}
}

// iphone5
@mixin iphone5() {
	@media screen and (max-width: 321px) {
		@content;
	}
}

// デバイス画像2倍
@mixin dppx() {
	@media (resolution: 2dppx) {
		@content;
	}
}

// pxをremに
@function rem($px) {
	@return ($px / 16) * 1rem;
}

//背景画像のimage-set設定
@mixin bg-image-set($url, $superratine: false) {
	background-image: url($url);
	background-image: getimageset($url, $superratine);
}

@function getimageset($url, $superratine: false) {
	@if $superratine {
		$x2: str-replace($url, ".png", "@2x.png");
		$x3: str-replace($url, ".png", "@3x.png");
		@return image-set($url 1x, $x2 2x, $x3 3x);
	}

	@else {
		$x2: str-replace($url, ".png", "@2x.png");
		@return image-set($url 1x, $x2 2x);
	}
}

//placeholder設定
@mixin placeholder($color) {
	&::placeholder {
		color: $color;
	}

	// IE
	&:-ms-input-placeholder {
		color: $color;
	}

	// Edge
	&::-ms-input-placeholder {
		color: $color;
	}
}

// icons
@mixin icon-size($size) {
	width: #{$size}px;
	height: #{$size}px;
}

@mixin fsize($max-size, $min-size, $breakpoint: "sm") {
	@include font-size($min-size);

	@include mq-down($breakpoint) {
		font-size: $max-size;
	}
}

// ========================
// hover - action
// ========================
// usang
// @include hover-underline('fade', 'top', 4px, #aaa);

// $typeでfadeを指定した場合はtopかbottomを、
// $typeでslideを指定した場合はleft-right, left-in-right, right-left, right-in-left, center

@mixin hover-underline($type: fade, $dir: null, $weight: 2px, $color: #000) {
	@if $dir ==null {
		@if $type ==fade {
			$dir: "top";
		}

		@else if $type ==slide {
			$dir: "center";
		}
	}

	position: relative;
	display: inline-block;
	text-decoration: none;

	&::after {
		position: absolute;
		left: 0;
		content: "";
		width: 100%;
		height: $weight;
		background: $color;

		@if $type ==fade {
			transition: 0.3s;
			opacity: 0;
			visibility: hidden;

			@if $dir ==bottom {
				bottom: $weight * -4;
			}

			@else {
				bottom: $weight;
			}
		}

		@else if $type ==slide {
			bottom: $weight * -2;
			transform: scale(0, 1);
			transition: transform 0.3s;

			@if $dir ==left-right or $dir ==right-in-left {
				transform-origin: left top;
			}

			@else if $dir ==right-left or $dir ==left-in-right {
				transform-origin: right top;
			}

			@else {
				transform-origin: center top;
			}
		}
	}

	@include hover {
		::after {
			@if $type ==fade {
				bottom: $weight * -2;
				opacity: 1;
				visibility: visible;
			}

			@else if $type ==slide {
				@if $dir ==left-in-right {
					transform-origin: left top;
				}

				@else if $dir ==right-in-left {
					transform-origin: right top;
				}

				transform: scale(1, 1);
			}
		}
	}
}

//透明
@mixin hover_opacity() {
	&:hover {
		filter: alpha(opacity=80);
		/* IE 6,7*/
		-ms-filter: "alpha(opacity=80)";
		/* IE 8,9 */
		-moz-opacity: 0.8;
		/* FF , Netscape */
		-khtml-opacity: 0.8;
		/* Safari 1.x */
		opacity: 0.8;
		zoom: 1;
		/*IE*/
	}
}

// fonts
@mixin noto-sans() {
	font-family: "Noto Sans JP", sans-serif;
}

@mixin poppins() {
	font-family: "Poppins", sans-serif;
}

@mixin hover() {
	@include mq-up(md) {
		&:hover {
			@content;
		}
	}
}

.pc-only {
	@include mq-down(md) {
		display: none !important;
	}
}

.sp-only {
	@include mq-up(md) {
		display: none !important;
	}
}

@mixin main-headline() {
	font-size: 3.8rem;
	color: $text-color;
	@include mq-down(md) {
		font-size: 2.4rem;
	}
}

@mixin sub-headline() {
	font-size: 1.8rem;
	color: $text-color;
	@include mq-down(md) {
		font-size: 1.6rem;
	}
}

@mixin text-under-mb {
	margin-bottom:4rem;
	@include mq-down(md) {
		margin-bottom:2rem;
	}
}

@mixin contents-under-mb {
	margin-bottom:10rem;
	@include mq-down(md) {
		margin-bottom:6rem;
	}
}

@mixin contents-box-shadow{
	box-shadow: 1px 1px 80px -10px rgba(0,0,0, 0.16);
}



