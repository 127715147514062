/*!------------------------------------*\
    Hamburger
\*!------------------------------------*/
.js-gnav-toggle {
	//@include mq-up($gnav-breakpoint) {
	//	display: none;
	//}

	border: 0;
	outline: 0;
	box-sizing: content-box;
	position: relative;
	//top: $gnav-toggle-top;
	//right: $gnav-toggle-right;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: $gnav-toggle-width;
	height: $gnav-navbar-height--sp;
	padding: 0;
	//padding-right: .75rem;
	//padding-left: .75rem;
	transition: all $gnav-transitionDuration $gnav-transitionFunction;
	transform: translate3d(0, 0, 0);
	background-color: transparent;
	//margin-top: -3px;
	@include mq-up($gnav-breakpoint) {
		z-index: ($gnav-overlay-zIndex + 4);
	}

	@include hover {
		cursor: pointer;
	}

	//&::before {
	//	content: 'MENU';
	//	font-size: 10px;
	//	position: absolute;
	//	bottom: $gnav-toggle-menuposition;
	//	line-height: 1;
	//	font-family: $gnav-toggle-fontfamily;
	//	color: $gnav-toggle-menucolor;
	//}

	&__icon,
	&__icon::after,
	&__icon::before {
		width: 100%;
		height: $gnav-toggle-wight;
		transition: all $gnav-transitionDuration $gnav-transitionFunction;
		background-color: $gnav-toggle-color;
		//border-radius: $gnav-toggle-radius;
	}

	&__icon {
		position: relative;
		display: block;

		&:before,
		&::after {
			content: "";
			position: absolute;
			left: 0;
			font-family: $gnav-toggle-fontfamily;
		}

		&:before {
			top: -#{$gnav-toggle-gutter};
		}

		&::after {
			top: $gnav-toggle-gutter;
		}
	}

	// アクティブ時
	&.is-active {
		//&::before {
		//  content: 'CLOSE';
		//}

		.js-gnav-toggle__icon {
			background-color: transparent;

			&:before,
			&:after {
				top: 0;
			}

			&:before {
				transform: rotate(45deg);
			}

			&:after {
				transform: rotate(-45deg);
			}
		}
	}
}
