.l-page{
    h2{
        // border-bottom: 1px solid #000;
        // padding: 4px 0 4px 20px;
        // position: relative;
        margin:5rem 0 3rem 0;
        @include mq-down(md) {
            margin-bottom: 2.5rem;
        }
        // &:before {
        //     content: "";
        //     background: #CB9A8D;
        //     width: 8px;
        //     height: 100%;
        //     position: absolute;
        //     left: 0;
        //     top: 0;
        //     z-index: 1;
        // }
    }
    h3,h4{
        margin: 3rem 0 1rem 0;
    }
    img{
        display: block;
        margin:3rem 0;
    }
    table{
        // width: fit-content;
        // margin:0 auto;
        // margin:3rem 0;
        // tr{
        //     border-top:2px solid #d5d5d5;
        //     border-bottom:2px solid #d5d5d5;
        //     td{
        //         padding:2rem;
        //     }
        //     td:nth-of-type(1){
        //         font-weight: bold;
        //         background:#f2f2f2;   
        //         min-width: 150px;
        //     }
        // }
        // h4{
        //     margin-top:2rem;
        // }
    }
}