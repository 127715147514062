.c-slide1 {
	@include mq-down(md) {
		padding: 0 10px;
	}

	&__inner {
		max-width: calc(100% - ((100% - 1520px) / 2) + 16px);
		margin: 0 0 0 auto;
		padding: 0 0 0 16px;
		@include mq-down(md) {
			max-width: 100%;
			padding: 0 20px 0 10px;
		}
	}

	&__wrap {
		list-style: none;
		padding: 0;
	}

	&__item {
		height: auto;
	}

	&__link {
		height: 100%;
		display: block;
	}

	&__img {
		position: relative;
		overflow: hidden;
		margin: 0;

		&::after {
			content: "";
			display: block;
			width: 100%;
			padding-top: 56.25%;
		}

		picture,
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__info {
		display: flex;
		align-items: center;
		padding: 20px 0;
	}

	&__logo {
		max-width: 200px;
		margin: 0;
		@include mq-down(md) {
			max-width: 160px;
		}

		picture,
		img {
			display: block;
		}
	}

	&__detail {
		flex: 1;
		margin: 0 0 0 30px;
	}

	&__ttl {
		@include font-size(1.6rem);
		line-height: 1.38;
		color: #1c1c1c;
		font-weight: bold;
		@include mq-down(md) {
			font-size: 1.6rem;
		}
	}

	&__scrollbar {
		max-width: calc(100% - 16px);
		width: 1520px;
		margin: 40px 0 0;
		@include mq-down(md) {
			max-width: none;
			width: auto;
			margin: 10px -20px 0 -10px;
		}

		.swiper-scrollbar {
			background: #f2f2f2;
			height: 4px;
		}

		.swiper-scrollbar-drag {
			background: #c62828;
		}
	}

	.swiper-button-prev {
		width: 48px;
		height: 48px;
		background: #ffffff;
		border-radius: 50%;
		box-shadow: 3px 3px 10px rgba($color: #000000, $alpha: 0.11);
		left: 10px;
		margin: -61px 0 0;
		@include mq-up(md) {
			display: none;
		}

		&::after {
			content: "";
			width: 8px;
			height: 5.5px;
			background: url("../img/icon/icon-arrow2.svg") no-repeat center / 100%;
		}

		&.swiper-button-disabled {
			opacity: 0;
		}
	}

	.swiper-button-next {
		width: 48px;
		height: 48px;
		background: #ffffff;
		border-radius: 50%;
		box-shadow: 3px 3px 10px rgba($color: #000000, $alpha: 0.11);
		right: 10px;
		margin: -61px 0 0;
		@include mq-up(md) {
			display: none;
		}

		&::after {
			content: "";
			width: 8px;
			height: 5.5px;
			background: url("../img/icon/icon-arrow.svg") no-repeat center / 100%;
		}

		&.swiper-button-disabled {
			opacity: 0;
		}
	}
}
