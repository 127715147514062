.c-text__layout__01{
  margin-bottom:100px;
  justify-content:space-around;
  h2.c-color__red{
    color:#C62828!important;
  }
  @include mq-down(lg) {
    justify-content: space-between;
  }
  @include mq-down(md) {
    margin-bottom:50px;
  }
  .c-text__layout__item{
    text-align: center;
    @include mq-down(md) {
      margin-bottom:50px;
      text-align: left
    }
    h2{
      @include main-headline();
    }
    p{
      @include sub-headline();
    }
  }
}