.c-categories {
	display: inline-flex;
	flex-wrap: wrap;
	margin: 0 0 12px;
	padding: 0;

	li {
		white-space: nowrap;
		list-style-type: none;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 12px 12px 0;
		font-size:12px;
		background-color:#C62828;
		padding:3px 10px;

		&:last-child {
			margin-right: 0;
		}
		&::before{
			display: none!important;
		}

		a,
		p {
			color: $white;
			// @include normal_transition();
			// @include hover_opacity();
			padding: 0 1px;
			margin-bottom: 0;
			font-size: 12px;
			font-weight: bold;
		}
	}

	&--round {
		li {
			border-radius: 20px;
		}
	}

}
