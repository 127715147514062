.c-qa{
	h2{
		text-align: center;
		margin-bottom:5rem;
	}
	.c-qa__item{
		width:100%;
		font-size: 2.5rem;
		margin-bottom:3rem;
		@include mq-down(xl) {
			font-size: 1.8rem;
		}
		.c-qa__item__q{
			color: white;
			background-color: $color5;
			padding:3rem;
		}
		.c-qa__item__a{
			padding:3rem;
		}
	}
}