.voice__wrap{
  margin: 0 calc(50% - 50vw);
  width: 100vw;
}

.c-single-voice-top {
  margin-top:12rem;
  margin-bottom: 10rem;
  @include mq-down(lg) {
    margin-bottom: 5rem;
  }

  .c-voice__img {
    img {
      width: 100%;
      object-fit: cover;
      aspect-ratio: 11 / 5;
    }
  }

  .c-voice__logos {
    position: relative;
    justify-content: center;

    .voice__logo {
      width: 350px;
      position: relative;
      bottom:6rem;
      @include mq-down(lg) {
        width:40vw;
      }
      @include mq-down(md) {
        bottom:3rem;
      }
      .voice__logo-inner{
        height: 160px;
        align-items:center;
        background-color: white;
        border:1px solid #ddd;
        @include mq-down(md) {
          height: 100px;
        }
        img{
          height: auto;
          max-height: 80%;
          max-width: 80%;
          width: auto;
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
        }
      }
      .voice__logo-text{
        text-align: center;
        color:$color5;
        margin-top:1rem;
      }
    }

    .c-voice__icon {
      width: 130px;
      height: 130px;
      align-self:center;
      text-align: center;
      bottom:4rem;
      position: relative;
      @include mq-down(md) {
        width: 8vw;
        bottom:0;
        object-fit: contain;
        padding:0 1rem;
      }
    }
  }
}

.c-loop--voice__title{
  font-size:2rem;
  a{
    color:#711717;
  }
}