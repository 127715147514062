.c-single__column{
	h1,h2,h3,h4,h5{
		font-weight: bold;
	}
	.ez-toc-list li{
		&::before{
			display: none;
		}
	}
	figure{
		margin-bottom:3rem;
	}
	ul{
		padding-left:0rem;
		margin-bottom:3rem;
		li{
			list-style:none;
			position: relative;
			&::before{
				display: inline-block;
				background-size: cover;
				content: "";
				background: url("../img/column/icon__check.png");
				background-repeat: no-repeat;
				background-size: cover;
				width: 15px;
				height: 13px;
				object-fit: cover;
				bottom: 0px;
				margin-right: 10px;
			}
		}
		ul{
			li{
				list-style:none;
				position: relative;
				&::before{
					display: inline-block;
					background-size: cover;
					content: "";
					background: url("../img/column/icon__ circle.png");
					background-repeat: no-repeat;
					background-size: cover;
					width: 8px;
					height: 8px;
					object-fit: cover;
					bottom: 0px;
					left: -18px;
					bottom:6px;
				}
			}
		}
	}
}