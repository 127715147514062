// footerレイアウト

.l-footer {
	$r: &;

	&__head {
		background-color: $main;
		color: #ffffff;
	}

	&__fixed {
		position: fixed;
		z-index: 100;
		left: 0;
		bottom: 0;
		width: 100%;
		background-color: $color1;
		@include mq-up(md) {
			display: none !important;
		}

		&.hide {
			display: none !important;
		}
	}

	&__fixedCol {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 50px;
	}

	&__headCol {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 180px;
		padding: 30px;
		@include mq-down(lg) {
			min-height: 179px;
			border-left: 0 !important;
			border-bottom: 1px solid rgba(255, 255, 255, 0.3);
		}
	}

	&__headCol + &__headCol {
		border-left: 1px solid rgba(255, 255, 255, 0.3);
	}

	&__text1 {
		@include font-size(1.8rem);
		font-weight: bold;
		line-height: 1.3333;
		text-align: center;
		display: block;
		color: #ffffff;
		@include mq-down(md) {
			font-size: 1.6rem;
			line-height: 1.375;
		}
	}

	&__text2 {
		@include poppins;
		@include font-size(1rem);
		line-height: 1.6;
		text-align: center;
		color: #ffffff;
		display: block;
	}

	&__link1 {
		@include hover {
			opacity: 0.85;

			#{$r}__text1 {
				color: #ffffff;
			}
		}

		&.icon1 {
			&:before {
				content: "";
				position: relative;
				display: block;
				width: 40px;
				height: 40px;
				background: url(../img/icon/icon-logomark.svg) no-repeat center/40px 40px;
				margin: 0 auto 27px;
				@include mq-down(md) {
					margin: 3px auto 29px;
				}
			}
		}

		&.icon2 {
			&:before {
				content: "";
				position: relative;
				display: block;
				width: 50px;
				height: 40px;
				background: url(../img/icon/icon-mail2.svg) no-repeat center/50px 40px;
				margin: 0 auto 27px;
				@include mq-down(md) {
					width: 40px;
					height: 32px;
					background-size: 40px 32px;
					margin: 13px auto 26px;
				}
			}
		}
	}

	&__link2 {
		padding: 0 10px;

		&.icon1 {
			&:before {
				content: "";
				position: relative;
				display: block;
				width: 20px;
				height: 20px;
				background: url(../img/icon/icon-logomark.svg) no-repeat center/20px 20px;
				margin: 0 auto 2px;
			}
		}

		&.icon2 {
			&:before {
				content: "";
				position: relative;
				display: block;
				width: 20px;
				height: 16px;
				background: url(../img/icon/icon-mail2.svg) no-repeat center/20px 16px;
				margin: 0 auto 2px;
			}
		}

		&.icon3 {
			&:before {
				content: "";
				position: relative;
				display: block;
				width: 14px;
				height: 20px;
				background: url(../img/icon/icon-smartphone-white.svg) no-repeat center/14px 20px;
				margin: 0 auto 2px;
			}
		}
	}

	&__phone {
		position: relative;
		color: #ffffff;

		&Link {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 27px;
			white-space: nowrap;
		}

		&Time {
			color: #ffffff;
			@include font-size(1.2rem);
			line-height: 1.7rem;
			white-space: nowrap;
			@include mq-down(md) {
				margin: -5px 0 0;
			}
		}

		&Tel {
			@include poppins;
			@include font-size(2.8rem);
			position: relative;
			font-weight: 600;
			line-height: 4.2rem;
			letter-spacing: 1.4px;
			color: #ffffff !important;
			display: flex;
			justify-content: center;
			align-items: center;
			// pointer-events: none;
			@include mq-down(md) {
				font-size: 2.8rem;
				line-height: 1.5;
				pointer-events: auto;
			}

			@include hover {
				color: #ffffff;
				opacity: 0.75;
			}

			&:before {
				content: "";
				position: relative;
				display: inline-block;
				width: 22px;
				height: 30px;
				background: url(../img/icon/icon-smartphone-white.svg) no-repeat center/22px 30px;
				margin-right: 20px;
				@include mq-down(md) {
					width: 30px;
					height: 42px;
					background-size: 30px 42px;
				}
			}
		}
	}

	&__inner {
		background: #f2f2f2;
		padding: 40px 0 0 0;
	}

	&__innerFlex1 {
		display: flex;
		justify-content: space-between;
		@include mq-down(md) {
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}

	&__logo {
		position: relative;
		@include mq-down(md) {
			text-align: center;
		}

		img {
			width: 274px;
		}

		&Text1 {
			@include noto-sans;
			@include font-size(1.8rem);
			display: block;
			margin-top: 17px;
			font-weight: 500;
			line-height: 1;
			@include mq-down(md) {
				margin-top: 21px;
			}
		}
	}

	&__social {
		display: flex;
		list-style: none;
		padding: 0;
		@include mq-down(md) {
			order: -1;
			margin-bottom: 40px;
		}

		li + li {
			margin-left: 20px;
		}

		a {
			display: block;
			width: 40px;
			height: 40px;
			text-align: center;
			img{
				width:40px;
				height: 40px;
			}

			// &.icon-facebook {
			// 	background: url(../img/icon/icon-facebook1.svg) no-repeat center/100%;
			// }

			// &.icon-twitter {
			// 	background: url(../img/icon/icon-twitter1.svg) no-repeat center/100%;
			// }

			// &.icon-instagram {
			// 	background: url(../img/icon/icon-instagram1.svg) no-repeat center/100%;
			// }
		}
	}

	&__copy {
		@include font-size(1.4rem);
		text-align: center;
		line-height: 1.357;
		padding: 27px 0 30px 0;
		@include mq-down(md) {
			font-size: 1.4rem;
			padding: 31px 0 30px;
		}
	}
}
