/*!------------------------------------*\
  Navbar
\*!------------------------------------*/
.js-gnav {
	&-navbar {
		top: 0;
	}

	&-nav {
		margin-top: 0;
		overflow: hidden;
		height: 100%;
		color: $primary;
		z-index: ($gnav-overlay-zIndex + 1);
		@include mq-up($gnav-breakpoint) {
			left: 0;
			overflow: visible;
			width: auto;
			padding-top: 0;
			//transform: translate3d(0, 0, 0);
		}
		@include mq-down($gnav-breakpoint) {
			position: fixed;
			top: 0;
			background: $gnav-menu-bg;
			padding-top: ($heder-hight--sp + 20px);
			width: $gnav-width;
		}
	}

	&-menu {
		overflow-y: scroll;
		height: 100%;
		padding: 0 0 $gnav-navbar-height--sp 0;
		margin: 0;
		list-style: none;
		font-size: 1rem;

		a {
			text-decoration: none;
			color: $gnav-menu-color;
			@include font-size($gnav-menu-fontsize);
			font-family: $gnav-menu-fontfamily;
			font-weight: $gnav-menu-fontweight;
			display: flex !important;
			align-items: center;
			//flex-direction: $gnav-menu-order;
			//flex-direction: column-reverse;
			//@include hover-underline($gnav-menu-hoverunderline--type, $gnav-menu-hoverunderline--dir, $gnav-menu-hoverunderline--width, $gnav-menu-hoverunderline--color);

			//&::after {
			//	bottom: 2px;
			//}

			@include hover {
				color: $gnav-menu-color--hover;
			}
		}

		span {
			@include font-size($gnav-menu-title-fontsize);
			font-weight: $gnav-menu-title-fontwight;
			@include mq-down($gnav-breakpoint) {
				display: none;
			}
		}

		@include mq-up($gnav-breakpoint) {
			padding: 0;
			height: 100%;
			overflow: visible;
			display: flex;
			justify-content: flex-end;
			> li {
				padding: 0 $gnav-menu-gutter;
				position: relative;
				display: flex;

				&:last-child {
					margin-right: 0;
				}
			}
		}
		//@include mq-down($gnav-breakpoint) {
		//	padding: 4%;
		//}
	}
}
