.c-searchform{
	margin:1rem 0 5rem 0;
	.c-searchform__contents{
		background:#F0F0E8;
		padding:2rem;
		input[type="text"]{
			width:80%;
			height:55px;
			@include mq-down(md) {
				width:70%;
				height: 45px;
			}
		}
		input::placeholder{
			padding-left:3rem;
		}
		button{
			background-color:#C62828;
			color:white;
			position: relative;
			width:15%;
			font-size:1.6rem;
			font-weight: bold;
			border: none;
			margin-left:1rem;
			border-radius: 5px;
			padding:1rem 0;
			height: 55px;
			bottom:1px;
			@include mq-down(md) {
				width:25%;
				height: 45px;
			}
		}
	}
}