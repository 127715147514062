.c-accordion {
  .accordion_one {
    .accordion_header {
      cursor: pointer;
      transition-duration: 0.2s;
      margin-top: 2rem;
      font-size: 1.6rem;
    }
  }
}
.c-accordion{
  .accordion_one{
    .accordion_inner{
      display: none;
      li{
        border:none;
        padding:2rem 2rem 0 2rem;
        a{
          color:black;
        }
      }
    }
  }
}

.arrow-right {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 10px;
  border-top: 4px solid #C62828;
  border-right: 4px solid #C62828;
  transform: rotate(45deg);
}

.open{
  span{
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  
}