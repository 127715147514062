.wp-block-image {
    margin-bottom: 100px;

    @include mq-down(md) {
        margin-bottom: 50px;
    }

    img {
        width: 100%;
    }
}
