.c-heading {
	&__inner {
		max-width: calc(100% - ((100% - 1520px) / 2) + 16px);
		margin: 0 0 0 auto;
		padding: 0 0 0 16px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		justify-content: space-between;
		@include mq-down(md) {
			max-width: 100%;
			padding: 0 0 0 10px;
		}
	}

	&__content {
		max-width: 400px;
		width: 30%;
		@include mq-down(md) {
			max-width: 100%;
			width: 100%;
		}
	}

	&__img {
		flex: 1;
		position: relative;
		overflow: hidden;
		margin: 0 0 0 40px;
		@include mq-down(md) {
			flex: none;
			width: calc(100% - 50px);
			margin: 0 0 40px auto;
			order: -1;
		}

		&::after {
			content: "";
			display: block;
			width: 100%;
			padding-top: 46.88%;
			@include mq-down(md) {
				padding-top: 54.5%;
			}
		}

		picture,
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.c-title3 {
		margin: 0 0 -6px;
		@include mq-down(md) {
			margin: 0;
		}

		&__jp {
			margin: 11px 0 0;
			@include mq-down(md) {
				margin: 21px 0 0;
			}
		}
	}
}

.c-line1 {
	position: relative;
	display: block;
	width: 100%;
	height: 10.2083vw;
	background: url(../img/common/line-top1.svg) no-repeat center/100% 100%;
	pointer-events: none;
	z-index: -1;
	margin: 0 0 -1px;
}

.c-text1 {
	@include font-size(1.4rem);
	line-height: 2;
	@include mq-down(md) {
		font-size: 1.2rem;
		line-height: 1.8;
	}
}

.c-news {
	position: relative;
	overflow: hidden;

	&__inner {
		display: flex;
		@include mq-down(md) {
			display: block !important;
		}
	}

	&__side {
		position: relative;
		background-color: #f2f2f2;
		width: 26.9736%;
		flex-shrink: 0;
		padding: 111px 20px 73px 0;
		display: flex;
		flex-direction: column;
		@include mq-down(md) {
			width: 100%;
			padding: 68px 0 9px;
		}

		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 50vw;
			height: 100%;
			pointer-events: none;
			background-color: #f2f2f2;
			z-index: -1;
			@include mq-down(md) {
				width: 100vw;
				left: 50%;
				transform: translateX(-50%);
				right: 0;
				margin: auto;
			}
		}

		.c-btn1 {
			margin-top: auto;
			@include mq-down(md) {
				display: none;
			}

			&__txt::after {
				background-color: #ffffff;
			}

			+ .c-btn1 {
				margin: 20px 0 0;
			}
		}
	}

	&__body {
		padding-left: 40px;
		width: 100%;
		@include mq-down(md) {
			padding-left: 0;
			padding-top: 12px;
		}
	}

	&__title1 {
		@include poppins;
		@include font-size(5.4rem);
		line-height: 1;
		font-weight: normal;
		letter-spacing: 0;
		margin-bottom: 72px;
		color: #373737;
		@include mq-down(md) {
			margin-bottom: 15px;
			font-size: 3rem;
			padding: 0 20px;
		}
	}

	&__desc {
		@include font-size(1.4rem);
		line-height: 1.8;
		letter-spacing: 0.1em;
		margin-bottom: 80px;
		@include mq-down(md) {
			padding: 0 20px;
			font-size: 1.4rem;
			line-height: 1.35;
			letter-spacing: 0;
			margin-bottom: 42px;
		}
	}

	&__category {
		margin: 0 0 57px;
		@include mq-down(md) {
			margin: 0;
			padding: 0 0 0 20px;
		}

		.swiper-scrollbar {
			width: calc(100% + 20px);
			position: relative;
			background: #f2f2f2;
			height: 4px;
			left: 0;
			bottom: auto;
			margin: 16px 0 0 -20px;
		}

		.swiper-scrollbar-drag {
			background: #c62828;
		}
	}

	&__cat {
		list-style: none;
		padding: 0;
		@include mq-up(md) {
			flex-wrap: wrap;
		}

		&__item {
			padding-left: 25px;
			width: 100%;
			height: auto;
			@include mq-down(md) {
				width: auto;
				padding: 0 0 2px;
			}

			&.is-active {
				position: relative;

				&:before {
					content: "";
					position: absolute;
					left: 0;
					top: 14px;
					width: 15px;
					height: 1px;
					background-color: $main;
					@include mq-down(md) {
						left: 50%;
						top: auto;
						bottom: 0;
						transform: translate(-50%, 0);
					}
				}

				a {
					color: $main;
				}
			}
		}

		li + li {
			margin-top: 19px;
			@include mq-down(md) {
				margin-top: 0;
			}
		}

		a {
			@include font-size(1.6rem);
			line-height: 1.375;
			color: #7c7c7c;
			@include mq-down(md) {
				font-size: 1.6rem;
				word-break: keep-all;
			}

			@include hover {
				color: $main;
				opacity: 1;
			}
		}
	}

	&__list {
		width: 100%;
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			border-bottom: 1px solid #b2b2b2;
		}

		&Top &Title {
			@include font-size(2.4rem);
			letter-spacing: 1.2px;
			line-height: 1.5;
			@include mq-down(md) {
				font-size: 1.8rem;
				line-height: 1.68;
				letter-spacing: 0;
			}
		}

		&Link {
			display: flex;
			align-items: center;
			padding: 30px 15px 28px;
			position: relative;
			@include mq-down(md) {
				display: block !important;
				padding: 25px 0 14px 0;
			}
		}

		&Img1 {
			width: 162px;
			flex-shrink: 0;
			margin: -5px 40px -5px -5px;
			position: relative;
			overflow: hidden;
			@include mq-down(md) {
				margin: 0 auto 31px;
				text-align: center;
			}

			&::after {
				content: "";
				display: block;
				padding-top: 142%;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		&Inner {
			position: relative;
			width: 100%;
		}

		&Head {
			display: flex;
			align-items: center;
			margin-bottom: 24px;
			@include mq-down(md) {
				margin-bottom: 15px;
			}
		}

		&Time {
			@include poppins;
			@include font-size(1.4rem);
			line-height: 1;
			margin-right: 20px;
			white-space: nowrap;
			color: #7c7c7c;
			letter-spacing: 0.7px;
			@include mq-down(md) {
				font-size: 1.4rem;
			}
		}

		&Cat {
			@include font-size(1.4rem);
			line-height: 1.42;
			text-align: center;
			padding: 0 15px;
			background-color: #e2e2e2;
			border-radius: 9px;
			color: $color1;

			&:not(:last-child) {
				margin: 0 5px 0 0;
			}
		}

		&Title {
			position: relative;
			@include font-size(1.8rem);
			line-height: 1.777;
			font-weight: bold;
			color: #1c1c1c;
			width: 100%;
			padding-right: 30px;
			@include mq-down(md) {
				font-size: 1.6rem;
				line-height: 2;
				letter-spacing: 0;
				padding-right: 20px;
			}

			&:after {
				content: "";
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 16px;
				height: 11px;
				background: url(../img/icon/icon-arrow.svg) no-repeat center/16px 11px;
				@include mq-down(md) {
					width: 11px;
					height: 8px;
					background-size: 100%;
				}
			}
		}
	}

	&__content {
		display: none;
	}
}
