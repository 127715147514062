.c-customers {
  margin-bottom: 5rem;
  .red{
    color:#C62828;
  }
  h3 {
    margin-bottom: 3rem;
    color: #3B4043;
  }

  .c-customers__contents {
    flex-wrap: wrap;

    .c-customer__item {
      position: relative;
      margin: 0 1%;
      background-color: white;
      border: 1px solid #707070;
      width: 31%;
      aspect-ratio: 3 / 2;
      margin-bottom: 2rem;
      @include mq-down(md) {
        width: 46%;
        margin: 0 2%;
        margin-bottom: 2rem;
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        max-height:80%;
        max-width: 80%;
      }
    }
  }
}