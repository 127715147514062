.pnavi{
	text-align: center;
	margin:30px 0;
	.page-numbers{
		padding:8px 12px;
		margin:5px;
		color:#1C1C1C;
		font-size:calc(1.265rem + .18vw);
	}
	.current{
		background-color:#C62828;
		border-radius: 50px;
	}
}