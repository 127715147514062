/*
* ページナビゲーション設定
*/

// アーカイブナビゲーション
 // ===============================
.navigation {
  padding: 3% 0;
  // PC
  &.pagination {
    text-align: center;
    .page-numbers {
      @include font-size(1.4rem);
      color: $text-color;
      text-decoration: none;
      margin: 0 8px;
      padding: .5em .8em;
      border-radius: 20px;
      &.current {
        background: $primary;
      }
    }
  }
  // SP
  &.posts-navigation {
    .nav-links {
      &::after {
        content: '';
        display: block;
        clear: both;
      }
    }
    .nav-previous,
    .nav-next {
      border: 1px solid $line-color;
      width: 49%;
      a {
        color: inherit;
        text-decoration: none;
        @include font-size(1.4rem);
        color: $text-color;
        display: flex;
        padding: 1em;
      }
    }
    .nav-previous {
      float: left;
      a {
        &::before {
          content: '«';
          margin-right: 8px;
        }
      }
    }
    .nav-next {
      float: right;
      a {
        justify-content: end;
        &::after {
          content: '»';
          margin-left: 8px;
        }
      }
    }
  }
}
