.bg-info {
    background-color: #F0F0E8;
}
.bg-gray {
    background-color: #EAEAEA;
}

.c-profile__top {
    margin: 3rem 0 6rem 0;
    .c-profile__info {
        .c-profile__img {
            margin-right: 2rem;
            p {
                margin: 0 !important;
            }

            img {
                margin: 0 !important;
                width: 40px;
                height: 40px;
                object-fit: cover;
                border-radius: 100px;
            }
        }

        .c-profile__title {
            p {
                line-height: 1.5 !important;
                margin-top: 0 !important;
                margin-bottom:5px!important
            }
            .c-profile__name{
                font-weight: bold;
                color: #1C1C1C;
            }
        }
    }
}

.c-profile__bottom{
    border-top:1px solid #DDDDDD;
    padding-top:3rem;
    margin-top:3rem;
    .c-profile__info {
        .c-profile__img {
            margin-right: 3rem;
            p {
                margin: 0 !important;
            }

            img {
                margin: 0 !important;
                width: 160px;
                height: 160px;
                object-fit: cover;
                border-radius: 100px;
                @include mq-down(md) {
                    width: 80px;      
                    height: 80px;              
                }
            }
        }

        .c-profile__title {
            width:75%;
            padding-left:3rem;
            @include mq-down(md) {
                width: 100%;  
            }
            p {
                line-height: 1.5 !important;
                margin-top: 0 !important;
                margin-bottom: 5px !important;
            }
            .c-profile__name{
                font-weight: bold;
                color: #1C1C1C;
                font-size:2rem;
                padding:.5rem 0 1rem 0;
            }
        }
    }
}

.info__category__sidebar {
    background-color: white;
    border-radius: 10px;
    padding: 2rem 2rem 0 2rem;

    @include mq-down(md) {
        display: none;
    }

    .info__category__title {
        font-size: 1.8rem;
        font-weight: bold;
    }

    ul {
        li {
            border-top: 2px solid #F1F1F1;

            h6 {
                padding-bottom: 1rem;
            }

            a {
                color: #1C1C1C;
                padding: 1rem 0;
                display: inline-block;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .categories {
            border: none;
        }

        .column-category {
            border: none;
        }

        h6 {
            border: none;
        }
    }
}

.info__category--popular__sidebar {
    background-color: white;
    border-radius: 20px;
    padding: 2rem;

    @include mq-down(md) {
        display: none;
    }

    h5 {
        padding-bottom: 1rem;
    }

    .ranking-box {
        padding: 1.5rem 0;
        border-top: 2px solid #F1F1F1;

        .ranking-box-left {
            width: 75%;
            padding-right: 1rem;
            font-size: 1.3rem;
            color: #3B4043;
        }

        .ranking-box-right {
            img {
                object-fit: cover;
                border-radius: 7px;
                height: 60px;
                width: 60px;
            }
        }
    }

    .ranking-info {
        .c-author__name {
            color: #3B4043;
            font-size: 1.2rem;
        }

        .c-loop--post__date__full {
            color: #3B4043 !important;
        }
    }

}

.top__scroll {
    display: none;
    width: 100%;
    text-align: center;
    background-color: #000000;

    a {
        display: block;
        color: white;
        padding: 2rem 0;
        font-weight: bold;
    }

    @include mq-down(md) {
        display: block;
        text-align: center;
    }
}