/*
* Breadcrumbs Settings
*/

.c-breadcrumbs {
	width: 100%;
	white-space: nowrap;
	overflow-x: auto;
	margin-bottom: 0;
}

.info-breadcrumb{
	p,span,a{
		color:#7C7C7C;
	}
}

.c-breadcrumbs__column{
	a,span{
		color:#C62828;
	}
	
}