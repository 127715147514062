/*
ボタンブロック装飾

命名規則
.is-style-btn-名前

ブロックエディタ内のボタンにも対応できるよう以下の記述も
.is-style-btn-名前 .wp-block-button__link { スタイル }
*/


.wp-block-button.is-style-btn-primary .wp-block-button__link{
  background: $primary !important;
  padding: .5em 1em;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  letter-spacing: .04em;
  &:hover {
    background: darken($primary, 15%);
  }
}

.wp-block-button.is-style-btn-accent .wp-block-button__link{
  background: $accent !important;
  padding: .5em 1em;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  letter-spacing: .04em;
  &:hover {
    background: darken($accent, 15%);
  }
}
