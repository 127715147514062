/*!------------------------------------*\
    Dropdown
\*!------------------------------------*/
.js-gnav-menu {
	.sub-menu {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		height: 0;
		opacity: 0;
		transition: opacity 0.5s;
		overflow: hidden;

		@include mq-up($gnav-breakpoint) {
			width: $gnav-submenu-width;
			position: absolute;
			left: 0;
			top: 100%;
			> li {
				width: 100%;
				background: $gnav-submenu-bg;
				padding: 1em;
				color: $gnav-submenu-color;
				border-bottom: $gnav-submenu-border;
				transition: 0.3s all ease-in;
				&:last-child {
					border-bottom: none;
				}
				@include hover {
					background: $gnav-submenu-bg__hover;
				}
				a {
					display: block;
					color: inherit;
					&::after {
						display: none;
					}
				}
			}
		}
	}

	// アクティブ時の設定
	li.is-active {
		.sub-menu {
			opacity: 1;
			height: auto;
			overflow: visible;
		}
	}
}
