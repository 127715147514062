/*
* 編集ボタン
*/
.edit-link {
	display: block;
	text-align: right;
	margin-top: 2rem;
	font-size: 1.2rem;

	.post-edit-link {
		display: inline-block;
		padding: 0.3rem 0.8em;
		color: $link-color;
		border-radius: 0.2rem;
		border: 1px solid $link-color;
		transition: all 0.3s ease-in-out;
		@include hover {
			background: rgba($link-color, 0.2);
			text-decoration: none;
		}
	}
}
