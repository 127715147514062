.l__info__header {
    height: 60px;
    line-height: 60px;

    @include mq-down(md) {
        height: 130px;
        line-height: normal;
        background-color: #C62828;
    }

    .info__header__left {
        font-size: 1.4rem;

        .info__header__logo {
            height: 100%;
            line-height: 100%;
            padding: 0 3rem;
            justify-content: center;

            @include mq-down(md) {
                text-align: center;
            }

            img {
                height: 50%;

                @include mq-down(md) {
                    height: 40px;
                    display: block;
                    margin: 0 auto;
                    padding-top: 1rem;
                }
            }
        }

        .info-title {
            @include mq-down(md) {
                display: block;
                text-align: center;
                color: white;
                font-weight: bold;
                padding-top: 2rem;
            }
        }
    }

    .info__header__right {
        padding-right: 4.5rem;
        font-size: 1.2rem;
        position: relative;

        &:after {
            content: '';
            display: inline-block;
            width: 15px;
            height: 15px;
            background-image: url("../img/info/icon_link.png");
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            right: 25px;
            top: 25px;
        }

        @include mq-down(md) {
            display: none;
        }
    }

    .info__header__category {
        padding-top:2.5rem;
        display: flex;
        overflow-x: scroll;
        width: 100%;
        ul {
            display: flex;
            justify-content: space-around;
            flex-wrap: nowrap;
            overflow-x: scroll;
            li {
                display: flex;
                color:white;
                display: block;
                text-align: center;
                white-space:nowrap;
                padding:0 2rem;
                a{
                    color: white;
                    font-weight: bold;
                }
            }
            .current-cat__column{
                background-color: white;
                color:#C62828;

            }
        }

    }
}


// グロナビ内
.info__gnav {
    height: 0 !important;
    background-color: white;
    .c-blog__top-title{
        border-bottom:2px solid #B2B2B2;
        padding-bottom:1rem;
        a{
            color:#1C1C1C;
        }
    }
    .info__gnav__category{
        ul{
            li{
                border:none;
                padding:2rem 2rem 0 2rem;
                a{
                    color:#1C1C1C;
                }
            }
        }
    }

    .l-header__btn {
        position: relative;
        z-index: 9999;
        bottom: 50px;
        right: 10px;
    }

    .l-header__btnNav {
        position: fixed;
        background-color: white;
        border-radius: 25px;
        bottom: 50px;
        right: 5px;
        .js-gnav-toggle{
            align-items:normal;
            width:25px;
        }

    }

    .l-header__btnToggle,
    .l-header__btnNav {
        width: 55px;
        height: 55px;

    }

    .js-gnav-toggle__icon {
        top:20px;
        &::before {
            background: rgba(198, 40, 40, 0.8);
            height: 4px;
            border-radius: 3px;
        }

        &::after {
            background: rgba(198, 40, 40, 0.8);
            height: 4px;
            border-radius: 3px;
        }
        
        background:rgba(198, 40, 40, 0.8);
        height: 4px;
        border-radius:3px;
    }
    .js-gnav-text{
        position: relative;
        &:after{
            content: "メニュー";
            font-size: 8px;
            position: absolute;
            width:50px;
            right:-15px;
            top:35px;
            color:#1C1C1C;
        }
    }

    .gnav__top-link {
        position: relative;
        &:after {
            content: '';
            display: inline-block;
            width: 15px;
            height: 15px;
            background-image: url("../img/info/icon_link.png");
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            left: 130px;
            top: 7px;
            pointer-events: none;
        }

        p {
            a {
                color: #1C1C1C;
                display: block;
            }
        }
    }
}