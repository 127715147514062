.table__layout_01 {
    margin-bottom: 10rem;
    width: 100%;
    padding: 0 1.5rem;

    @include mq-down(md) {
        margin-bottom: 6rem;
    }

    tr {
        td:nth-of-type(1) {
            width: 20%;
            padding: 2rem 0;
            border-top: 5px solid #CDD6DD;

            @include mq-down(md) {
                padding: 20px 0;
            }

            h3 {
                color: #3B4043;
                font-size: 2.4rem;

                @include mq-down(md) {
                    font-size: 2rem;
                }
            }

            p {
                font-size: 1.6rem;
                font-weight: bold;
                padding-top: 2rem;

                @include mq-down(md) {
                    font-size: 1.2rem;
                    padding-top: 1rem;
                }
            }
        }

        td:nth-of-type(2) {
            width: 80%;
            border-top: 1px solid #CDD6DD;
            padding-left: 2rem;

            @include mq-down(md) {
                font-size: 1.2rem;
            }
        }
    }

    tr:last-child>* {
        border-bottom: 1px solid #CDD6DD;
    }
}