.youtube {
	position: relative;
	width: 100%;
	padding-top: 30%;
	@include mq-down(md) {
		padding-top:0;
		height: 180px;
	}
	iframe {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		clip: unset!important;
	}
}

.wp-embed__read-more{
	text-align:center;
	background-color: black;
	color: white;
	font-size:14px;
	font-weight: bold;
	border-radius: 30px;
	width:100px;
	display:block;
	padding:3px 10px;
	margin-right:0;
	margin-left:auto;
}
.wp-embedded-content{
	a{
		position: absolute;
		height: 300px;
		display: block;
	}
}
#wp-embed{
	display:flex;
	border:none;
	box-shadow:none;
	@include mq-down(sm) {
		padding:10px 0 0 0!important;
	}
}
.wp-embed-heading{
	margin-top:1rem!important;
	margin-left:2.0rem!important;
	a{
		font-size:16px;
		line-height:1.6;
	}
}
.wp-embed__read-more{
	a{
		color:white;
	}
}

.blog-card__title{
	text-align: center;
	font-size: 1.4rem;
	font-weight: bold;
	border:1px solid black;
	padding:1rem 0;
}
blockquote{
	// display: none!important;
}
// wp-admin標準フォント
// body.wp-admin .editor-styles-wrapper {
// 	font-family: $font-family-base;
// }

@mixin block-margin-bottom() {
	margin-bottom: 2rem;
}
