.js-gnav-pc {
	position: fixed;
	z-index: ($gnav-overlay-zIndex + 1);
	background-color: #ffffff;
	visibility: hidden;
	overflow: hidden;
	opacity: 0;
	top: 0;
	left: 0;
	width: 0;
	height: 0;

	&.is-active {
		-webkit-transition: opacity 0.3s ease-in-out;
		transition: opacity 0.3s ease-in-out;
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
		width: 100vw;
		height: 100vh;
	}
}
