.u-f {
  // カラー
  &-color {
    &--primary {
      color: $primary !important;
    }
    &--yellow {
      color: $primary !important;
    }
    &--red {
      color: $primary !important;
    }
    &--green {
      color: $primary !important;
    }
  }

  // フォントサイズ
  &-size {
    &--xs {
      font-size: $font-size-xs !important;
    }
    &--sm {
      font-size: $font-size-sm !important;
    }
    &--default {
      font-size: $font-size-base !important;
    }
    &--lg {
      font-size: $font-size-lg !important;
    }

    @each $tag , $font-size in $font-sizes {
      &--#{$tag} {
        @include font-size(#{$font-size});
      }
    }
  }

  // 太さ
  &-weight {
    &--w7 {
      font-weight: 700;
    }
    &--w5 {
      font-weight: 500;
    }
    &--w4 {
      font-weight: 400;
    }
  }

  // 書体
  &-family {
    &--webfont {
      font-family: $web-font;
    }
  }
}
