/*
* Pagetop Settings

pagescroll.jsと連動
*/

.c-pagetop {
	cursor: pointer;
	background: $base;
  width: 45px;
  height: 45px;
  right: 16px;
  bottom: 16px;
}
