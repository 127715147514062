.c-select-work {
    margin-bottom: 2rem;
    .c-select-work__item {
        position: relative;
        width:20%;
        @include mq-down(lg) {
            width:45%;
            margin-bottom:2rem;
        }
        @include mq-down(md) {
            width:48%;
        }
        &:after {
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 9px solid black;
            content: "";
            position: absolute;
            right: 15px;
            top: 25px;
            width: 0;
        }
        select {
            position: relative;
            width: 100%;
            height: 55px;
            text-align: center;
            display: block;
            -webkit-appearance: none;
            appearance: none;
            /* デフォルトの矢印を非表示 */
        }
    }
}