//セミナーアーカイブページ
.p-seminar__contents{
	h2{
		@include main-headline();
		margin-bottom:50px;
		@include mq-down(md) {
			margin-bottom:25px;
		}
	}
	.c-seminar__contents__item{
		margin-bottom:150px;
		@include mq-down(md) {
			margin-bottom:50px;
		}
		.c-seminar__img{
			width:60%;
			@include mq-down(md) {
				width:100%;
			}
			img{
				width:100%;
			}
		}
		.past{
			position: relative;
			&::before{
				content: "受付終了";
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translateY(-50%) translateX(-50%);
				font-size: 45px;
				color:white;
				z-index: 5;
				font-weight: bold;
				@include mq-down(md) {
					font-size:18px;
				}
			}
			&::after{
				position: absolute;
				background:rgba(181,0,0,0.5);
				display: inline-block;
				content: "";
				width: 100%;
				height: 100%;
				z-index: 1;
				bottom:0;
				left:0;
			}
		}
		.c-seminar__info{
			padding:70px 60px;
			background-color:white;
			@include contents-box-shadow();
			width:40%;
			height: fit-content;
			position: relative;
			right:50px;
			margin-top:15rem;
			z-index: 2;
			@include mq-down(xl) {
				padding:40px 30px;
			}
			@include mq-down(lg) {
				width:50%;
				margin-top:10rem;
			}
			@include mq-down(md) {
				padding:20px 15px;
				width:100%;
				margin-top:0rem;
				right:0px;
				bottom:50px;
			}
			dl{
				display: flex;
				font-size:2.4rem;
				.delimiter{
					padding:0 10px;
				}
				@include mq-down(lg) {
					font-size:2.2vw;
				}
				@include mq-down(md) {
					font-size:1.8rem;
				}
			}
			dt{
				text-align: justify;
				width:110px;
				@include mq-down(lg) {
					width:90px;
				}
			}
			.c-seminar__info__btn{
				text-align: left;
			}
		}
	}
}