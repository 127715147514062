.c-list1 {
	padding: 0;
	list-style: none;
	@include mq-down(md) {
		padding: 0 20px;
	}

	&__wrap {
		width: auto;
		flex-wrap: wrap;
		padding: 0;
		list-style: none;
		margin: 0 -16px;
		@include mq-down(md) {
			flex-wrap: nowrap;
			margin: 0;
		}
		@include mq-up(md) {
			transform: none !important;
		}
	}

	&__item {
		width: 100%;
		height: auto;
		margin: 0 0 20px;
		display: none;
		@include mq-up(md) {
			margin: 0 0 20px !important;
			width: 33.33333% !important;
			padding: 0 16px;
		}
		@include mq-down(md) {
			margin: 0;
		}
	}

	&__link {
		background: #fff;
		display: block;
		height: 100%;
		box-shadow: 3px 7px 15px rgba($color: #000000, $alpha: 0.06);
	}

	&__img {
		position: relative;
		overflow: hidden;
		margin: 0;

		&::after {
			content: "";
			display: block;
			width: 100%;
			padding-top: 56.25%;
		}

		picture,
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
			object-position:top;
		}
	}

	&__info {
		padding: 23px 20px 32px;
		@include mq-down(md) {
			padding: 16px 15px 22px;
		}
	}

	&__ttl {
		@include font-size(1.8rem);
		line-height: 1.5;
		font-weight: bold;
		color: #1c1c1c;
		@include mq-down(md) {
			font-size: 1.6rem;
			line-height: 1.4;
		}
	}

	&__txt {
		@include font-size(1.3rem);
		line-height: 1.4;
		font-weight: 500;
		color: #7c7c7c;
		margin: 15px 0 0;
		@include mq-down(md) {
			font-size: 1.2rem;
			margin: 7px 0 0;
		}
	}

	&__scrollbar {
		margin: 44px -20px 0;
		@include mq-up(md) {
			display: none;
		}

		.swiper-scrollbar {
			background: #f2f2f2;
			height: 4px;
		}

		.swiper-scrollbar-drag {
			background: #c62828;
		}
	}

	.swiper-button-prev {
		width: 48px;
		height: 48px;
		background: #ffffff;
		border-radius: 50%;
		box-shadow: 3px 3px 10px rgba($color: #000000, $alpha: 0.11);
		left: 10px;
		margin: 11px 0 0;
		@include mq-up(md) {
			display: none;
		}

		&::after {
			content: "";
			width: 8px;
			height: 5.5px;
			background: url("../img/icon/icon-arrow2.svg") no-repeat center / 100%;
		}

		&.swiper-button-disabled {
			opacity: 0;
		}
	}

	.swiper-button-next {
		width: 48px;
		height: 48px;
		background: #ffffff;
		border-radius: 50%;
		box-shadow: 3px 3px 10px rgba($color: #000000, $alpha: 0.11);
		right: 10px;
		margin: 11px 0 0;
		@include mq-up(md) {
			display: none;
		}

		&::after {
			content: "";
			width: 8px;
			height: 5.5px;
			background: url("../img/icon/icon-arrow.svg") no-repeat center / 100%;
		}

		&.swiper-button-disabled {
			opacity: 0;
		}
	}
}

/*
------------------------------------------------------------*/
.c-list2 {
	list-style: none;
	padding: 0;
	box-shadow: 3px 7px 20px rgba($color: #000000, $alpha: 0.1);
	margin: 0;

	&__item {
		background: #fff;
		padding: 0;

		&:not(:nth-child(3n)) {
			border-right: 1px solid #f5f5f5;
			@include mq-down(md) {
				border-right: 0;
			}
		}
	}

	&__img {
		position: relative;
		overflow: hidden;
		margin: 0;

		&::after {
			content: "";
			display: block;
			width: 100%;
			padding-top: 55.65%;
			@include mq-down(md) {
				padding-top: 41%;
			}
		}

		picture,
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__info {
		padding: 34px 30px 57px;
		@include mq-down(md) {
			padding: 33px 25px 41px 30px;
		}
	}

	&__ttl {
		@include font-size(2.4rem);
		line-height: 1.38;
		color: #c62828;
		font-weight: bold;
		@include mq-down(md) {
			font-size: 1.8rem;
			line-height: 1.68;
			letter-spacing: 0.9px;
		}
	}

	&__txt {
		@include font-size(1.4rem);
		line-height: 2;
		color: #1c1c1c;
		margin: 22px 0 0;
		@include mq-down(md) {
			font-size: 1.2rem;
			line-height: 1.8;
			margin: 21px 0 0;
		}
	}

	.c-btn2 {
		margin: 34px 0 0;
		@include mq-down(md) {
			margin: 45px 0 0;
		}
	}
}

/*
------------------------------------------------------------*/
.c-list3 {
	list-style: none;
	padding: 0;
	$root: &;
	@include mq-down(md) {
		margin: 0 -10px;
	}

	&__item {
		margin: 0 0 40px;
		@include mq-down(md) {
			padding: 0 10px;
			margin: 0 0 8px;
		}
	}

	&__txt {
		display: flex;
		align-items: center;
		height: 100%;
		@include font-size(1.8rem);
		line-height: 1.35;
		color: #1c1c1c;
		position: relative;
		border-bottom: 1px solid #b2b2b2;
		padding: 36px 50px 36px 30px;
		@include mq-down(md) {
			font-size: 1.4rem;
			padding: 18px 20px 18px 0;
		}

		&::after {
			content: "";
			width: 18px;
			height: 14px;
			background: url("../img/icon/icon-arrow.svg") no-repeat center / 100%;
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translate(0, -50%);
			@include mq-down(md) {
				width: 12px;
				height: 9.5px;
				right: 0;
			}
		}
	}

	&--col2 {
		#{$root}__item {
			margin: 0 0 31px;
			@include mq-down(md) {
				margin: 0 0 8px;
			}
		}

		#{$root}__txt {
			@include font-size(2rem);
			padding: 36px 50px 36px 20px;
			@include mq-down(md) {
				font-size: 1.4rem;
				padding: 18px 20px 18px 0;
			}

			&::after {
				width: 20px;
				height: 15px;
				@include mq-down(md) {
					width: 12px;
					height: 9.5px;
				}
			}
		}
	}
}

.c-list4{
	&__img{
		width:100%;
		object-position: bottom;
		aspect-ratio: 9 / 10;
		
	}
}
