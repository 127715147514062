/*
* Animation Settings
*/

// lazysize
.lazyload{
  opacity: 0;
}
.lazy-loaded{
  opacity: 1;
  transition: all .8s ease-in-out;
}

// jsスクロールインアニメーション
.js-in {
  opacity: 0;
	animation-delay: -2s;
  transition: all .8s ease-in-out;
  &.is-active {
    opacity: 1 !important;
  }

  &--up {
    transform: translateY(30%);
    &.is-active {
      transform: translateY(0%);
    }
  }

  &--right {
    transform: translate3d(100%, 0, 0);
    &.is-active {
      transform: translate3d(0, 0, 0);
    }
  }

  &--left {
    transform: translate3d(-100%, 0, 0);
    transition: all 1s ease-in;
    &.is-active {
      transform: translate3d(0, 0, 0);
    }
  }

}

// 点滅
.a-blinking {
	animation: blink 1s ease-in-out infinite alternate;
}
@keyframes blink {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

