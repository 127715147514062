.c-btn {
	display: inline-block;
	font-weight: 400;
	line-height: 1;
	color: $text-color;
	text-align: center;
	vertical-align: middle;
	text-decoration: none;
	cursor: pointer;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	@include font-size(1.6rem);
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;

	a {
		color: inherit;
	}

	&--primary {
		border: 1px solid $text-color;
		padding: 1em 3.5em 1em 2em;
		min-width: 200px;
		font-family: $web-font;
		font-weight: 500;
		position: relative;
		text-align: left;
		background: #fff;

		&::before {
			content: "";
			width: 100%;
			height: 100%;
			display: block;
			border-bottom: 1px solid $text-color;
			border-right: 1px solid $text-color;
			position: absolute;
			top: 8px;
			right: -8px;
		}

		&::after {
			content: "";
			@include icon-size("24");
			mask: url("../../icomoon/SVG/arrow.svg");
			mask-size: contain;
			mask-repeat: no-repeat;
			background-color: $text-color;
			position: absolute;
			right: 16px;
			top: 50%;
			transform: translateY(-50%);
			transition: 0.3s ease-in;
		}

		@include hover {
			background-color: $primary;
			color: $text-color;

			&::after {
				right: 3px;
			}
		}
	}

	&--cv {
		@extend .c-btn--primary;
		background: $primary;
		font-weight: bold;
		min-width: 280px;

		span {
			display: flex;
			align-items: center;
			justify-content: center;

			&::before {
				content: "";
				width: 1em;
				height: 1em;
				mask-image: url("../../icomoon/SVG/mail.svg");
				mask-size: contain;
				mask-repeat: no-repeat;
				background-color: $text-color;
				right: auto;
				left: 16px;
				display: inline-block;
				padding-right: 2em;
			}
		}

		&::after {
			display: none;
		}
	}

	&--line {
		position: relative;
		padding: 8px 3em 8px 8px;
		border-bottom: 1px solid $text-color;
		@include font-size(1.4rem);

		&::after {
			@extend .c-btn--primary::after;
			right: 8px;
		}
	}
}

/*
------------------------------------------------------------*/
.c-btn1 {
	&__txt {
		display: inline-flex;
		align-items: center;
		@include font-size(1.6rem);
		line-height: 1.38;
		color: #1c1c1c;
		white-space: nowrap;
		@include mq-down(xl) {
			@include font-size(1.4rem);
		}
		@include mq-down(md) {
			font-size: 1.4rem;
		}

		&::after {
			display: block;
			content: "";
			width: 60px;
			height: 60px;
			border-radius: 50%;
			background: url("../img/icon/icon-arrow.svg") no-repeat center / 16px 11px, #f2f2f2;
			margin: 0 0 0 47px;
			flex-shrink: 0;
			@include mq-down(xl) {
				width: 30px;
				height: 30px;
				margin: 0 0 0 20px;
			}
			@include mq-down(md) {
				width: 60px;
				height: 60px;
				margin: 0 0 0 20px;
			}
		}
	}
}

/*
------------------------------------------------------------*/
.c-btn2 {
	max-width: 100%;
	&__txt {
		display: inline-block;
		border: 1px solid #c62828;
		box-sizing: border-box;
		@include font-size(1.6rem);
		line-height: 1.38;
		color: #c62828;
		font-weight: bold;
		padding: 12px 16px 12px 30px;
		@include mq-down(md) {
			color: #c62828 !important;
			font-size: 1.4rem;
			padding: 10px 13px 10px 27px;
		}

		@include hover {
			color: #ffffff;
			background-color: $main;
			opacity: 1;

			span:after {
				filter: brightness(0) invert(1);
			}
		}

		span {
			display: inline-block;
			position: relative;
			padding: 0 42px 0 0;

			&::after {
				content: "";
				width: 16px;
				height: 11px;
				background: url("../img/icon/icon-arrow.svg") no-repeat center / 16px 11px;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translate(0, -50%);
			}
		}
	}

	&--center {
		text-align: center;
	}
}

/*
------------------------------------------------------------*/
.c-btn3 {
	&--color1 &__link {
		background-color: $color1;
		@include hover {
			background-color: rgba($color1, 0.8);
		}
	}

	&__link {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		@include noto-sans;
		@include font-size(1.6rem);
		font-weight: bold;
		line-height: 2.2rem;
		text-align: center;
		background-color: $main;
		border-radius: 4px;
		width: auto;
		max-width: 100%;
		height: 56px;
		color: #ffffff !important;
		padding: 10px;
		@include hover {
			color: #ffffff;
			background-color: rgba($main, 0.8);
			opacity: 1;
		}
	}
}

.c-btn4 {
	max-width: 100%;
	&__txt {
		display: inline-block;
		border: 1px solid #c62828;
		background-color: #f2f2f2!important;
		box-sizing: border-box;
		@include font-size(1.6rem);
		line-height: 1.38;
		color: #c62828;
		font-weight: bold;
		padding: 12px 30px 12px 30px;
		@include mq-down(md) {
			color: #c62828 !important;
			font-size: 1.4rem;
			padding: 10px 27px 10px 27px;
		}

		@include hover {
			color: #ffffff;
			background-color: $main;
			opacity: 1;

			span:after {
				filter: brightness(0) invert(1);
			}
		}

		span {
			display: inline-block;
			position: relative;
			padding: 0 42px 0 0;

			&::after {
				content: "";
				width: 16px;
				height: 11px;
				background: url("../img/icon/icon-arrow.svg") no-repeat center / 16px 11px;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translate(0, -50%);
			}
		}
	}

	&--center {
		text-align: center;
	}
}
