// 年表レイアウト01
.timeline-01 {
    .timeline {
        list-style: none;
        list-style-type: none;
        padding: 0;
    }

    .timeline>li {
        margin-bottom: 60px;
        list-style: none;
        list-style-type: none;
        margin: 0;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        padding: 0;

        &:nth-child(1) {
            .timeline-content:before {
                content: '';
            }
        }

    }

    .timeline-date {
        width: 90px;
        min-width: 90px;
        line-height: 24px;
        margin-bottom: 0;
        margin-top: -5px;        

        @include mq-down(sm) {
            width: 80px;
            min-width: 80px;
        }
    }

    .timeline-content {
        min-width: 0;
        flex-grow: 0;
        border-left: 4px #3C4043 solid;
        padding-left: 60px;

        @include mq-down(sm) {
            padding-left: 40px;
        }

        p {
            margin-bottom: 60px;
            margin-top: -6px;
        }
    }

    .timeline-content:before {
        content: '';
        width: 18px;
        height: 18px;
        background: #3C4043;
        position: absolute;
        top: -2px;
        left: 83px;
        border-radius: 100%;

        @include mq-down(sm) {
            left: 73px;
        }
    }
}