.c-top__section__contents{
	margin:200px 0 50px 0;
	@include mq-down(xl) {
		margin:100px 0 50px 0;
	}
	.c-top__section__title{
		display: flex;
		@include mq-down(lg) {
			padding-bottom:25px;
		}
		h1{
			@include main-headline();
			color:#1C1C1C;
		}
		p{
			padding:40px 0 0 20px;
			letter-spacing: 50px;
			letter-spacing: 3px;	
			@include sub-headline();
			color:#C62828;
			@include mq-down(md) {			
				padding:20px 0 0 10px;
			}
		}
	}
	.c-top__section__img{
		text-align: right;
		padding-bottom:25px;
		@include mq-down(lg) {
			width:50%;
			margin-right:0;
			margin-left:auto;
			object-fit: contain;
		}
	}
}