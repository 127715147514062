//タイトルレイアウト01
.title__layout-01 {
    margin-bottom: 10rem;

    .title__en {
        font-size: 1.8rem !important;
        font-weight: normal !important;

        @include mq-down(md) {
            font-size: 1.6rem !important;
        }
    }

    @include mq-down(md) {
        margin-bottom: 5rem;
    }

    h2 {
        font-weight: bold;
        @include main-headline();
    }
}

.title__layout-02 {
    margin-bottom: 10rem;

    @include mq-down(md) {
        margin-bottom: 5rem;
    }

    h2 {
        @include main-headline();
        font-weight: bold;

        .title__en {
            font-size: 1.8rem !important;
            font-weight: normal !important;

            @include mq-down(md) {
                font-size: 1.6rem !important;
            }
        }
    }
}