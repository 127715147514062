/*
見出しブロック装飾

命名規則
.is-style-h_名前
*/

.wp-block-heading.is-style-h-underline,
.is-style-h-underline {
  border-bottom: 1px solid $text-color;
  padding: .5em 0;
  margin: 1em 0 1.5em;
}

.wp-block-heading.is-style-h-ub,
.is-style-h-ub {
  border-bottom: 1px solid $text-color;
  border-top: 1px solid $text-color;
  padding: .3em 0;
  margin: 1em 0 1.5em;
}

.wp-block-heading.is-style-h-leftline,
.is-style-h-leftline {
  border-left: 2px solid $text-color;
  padding: 0 0 .2em .8em;
  margin: 1em 0 1.5em;
}

.wp-block-heading.is-style-h-bg,
.is-style-h-bg {
  background: $base;
  margin: 1em 0 1.5em;
  padding: .3em .5em;
  border-radius: 2px;
}