.p-single {
	article {
		img{
			display: block;
		}
		.c-entry-content {

			h1,
			h2,
			h3,
			h4,
			h5 {
				margin: 6rem 0 3rem 0;
			}
			h1{
				font-size: 3rem;
				@include mq-down(md) {
					font-size: 2rem;
				}
			}

			h2 {
				border-bottom: 1px solid #000;
				padding: 4px 0 4px 0;
				position: relative;
				font-size:2.0rem;
				@include mq-down(md) {
					font-size: 1.8rem;
				}
				&:before {
					content:"";
					background:black;
					width:30%;
					height: 2px;
					position:absolute;
					left:0;
					bottom:0;
					z-index: 1;
				}
			}
			h3,h4,h5{
				padding: 4px 0 4px 0px;
				position: relative;
				font-weight: bold;
				font-size: 1.8rem;
				@include mq-down(md) {
					font-size: 1.6rem;
				}
			}

			p {
				line-height: 1.6;
				font-size:1.6rem;
				margin-bottom: 3rem;
			}

			.wp-block-image {
				img {
					max-width: 100%;
					width: auto;
					margin-top: 3rem;
				}
			}
		}
	}
}