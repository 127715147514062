/*!------------------------------------*\
    Left
\*!------------------------------------*/

.js-gnav-nav {
	&--left {
		@include mq-down($gnav-breakpoint) {
			left: (0 - $gnav-width);
			transition: left $gnav-transitionDuration $gnav-transitionFunction;

			// アクティブ時
			&.is-active {
				left: 0;
				a {
					align-items: flex-start;
				}
			}
		}
	}
}
