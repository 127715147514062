.c-service__contents__01{
	.c-service__contents__item__q,.c-service__contents__item__a{
		background-color:#F2F2F2;
		border-radius: 20px;
		padding:4rem 3rem 2rem 3rem;
		margin-bottom:15rem;
		text-align: center;
		width:30%;
		aspect-ratio: 9 / 10;
		@include mq-down(lg) {
			padding:4rem 2rem 2rem 2rem;
		}
		@include mq-down(md) {
			width:90%;
			margin:0 auto;
			margin-bottom:12rem;
			aspect-ratio: unset;
		}
		p{
			@include mq-down(lg) {
				font-size:1.6rem;
			}
		}
		img{
			height: 55%;
			object-fit: contain;
			margin-bottom:4rem;
		}
	}
	.c-service__contents__item__q{
		position: relative;
		h3{
			position: relative;
			bottom:6.5rem;
			margin-bottom:-5rem;
			@include mq-down(md) {
				bottom:6rem;
			}
		}
		&::after{
			position: absolute;
			display: inline-block;
			background-size: cover;
			background-repeat: no-repeat;
			content: "";
			background-image: url("../img/homepage/icon__arrow__red__bottom.png");
			position: relative;
			width: 184px;
			height: 82px;
			bottom: -100px;
			left: 0px;
		}
	}
	.c-service__contents__item__a{
		background-color: #F0F0E8;
		h3{
			padding-bottom:5rem;
			@include mq-down(lg) {
				padding-bottom:2rem;
				font-size:2.5rem;
			}
		}
		p{
			letter-spacing: 3px;
			text-align: left;
		}
		.red{
			color:#C62828;
			margin-bottom:3rem;
		}
		img{
			width:80%;
			height: auto;
			object-fit: contain;
			position: relative;
			bottom:8rem;
			margin-bottom:-6rem;
		}
	}
	.child_4{
		@include mq-up(md) {
			order:4
		}
	}
	.child_5{
		@include mq-up(md) {
			order:5;
		}
	}
	.child_6{
		@include mq-up(md) {
			order:6;
		}
	}
}

.p-service__contents__01{
	background-color: #F0F0E8;
	padding:3rem 0 6rem 0;
	margin-bottom:7rem;
	.c-speech__bubble{
		position:relative;
		text-align: center;
		bottom:6rem;
		img{
			max-width:480px;
			margin:0 auto;
			display: block;
			@include mq-down(sm) {
				width:80%;
			}
		}
		@include mq-down(sm) {
			bottom:4rem;
			margin-bottom:-2rem;
		}
	}
	.p-service__contents__box{
		img{
			width:50%;
			@include mq-down(lg) {
				width:80%;
				margin:0 auto;
				display: block;
				margin-bottom:3rem;
			}

		}
		.c-service__contents__box__info{
			margin-left:8rem;
			@include mq-down(lg) {
				margin-left:0rem;
			}
			h3{
				margin-bottom:2rem;
			}
			.c-service__contents__box__about{
				background-color: white;
				border:1px solid white;
				border-radius: 18px;
				padding:1rem 3rem 4rem 3rem;
				h4{
					position: relative;	
					bottom:3.5rem;
					margin-bottom:-1rem;
					color:#CA4444;
					@include mq-down(lg) {
						bottom:3rem;
					}
				}
			}
		}
	}
}

.c-service__text__contents__01{
	margin-bottom:10rem;
	@include mq-down(md) {
		margin-bottom:6rem;
	}
	h2{
		text-align: center;
		@include main-headline();
		margin-bottom:4rem;
		@include mq-down(md) {
			margin-bottom:2rem;
		}
	}
	.c-service__text__contents__info{
		width: fit-content;
		margin:0 auto;
		line-height: 3;
		margin-top:5rem;
	}
}

.c-service__title__01{
	text-align: center;
	background-image: url("../img/homepage/c-service__title__01.png");
	background-repeat: no-repeat;
	background-size:cover;
	background-position:bottom right;
	padding:20vw 0;
	margin-bottom:10rem;
	h2{
		@include main-headline();
	}
}

.p-comman__style{
	@include contents-under-mb();
	.c-comman-style__title{
		text-align: center;
		max-width:540px;
		margin:0 auto;
		margin-bottom:8rem;
		@include mq-down(md) {
			margin-bottom:4rem;
		}
		img{
			width:100%;
			padding:0 2rem;
		}
	}
	.c-comman__style__contents{
		.row{
			@include mq-down(md) {
				margin:0 15px;
			}
		}
		.c-comman__style__contents__item{
			width:48%;
			box-shadow: 1px 1px 30px -10px #000000;
			padding:7rem 4rem 40rem 4rem;
			border-radius: 15px;
			position: relative;
			margin-bottom:5rem;
			@include mq-down(md) {
				width:100%;
				padding:5rem 2rem 30rem 2rem;
			}
			h4{
				text-align: center;
			}
			table{
				margin-top:8rem;
				@include mq-down(md) {
					margin-top:4rem;
				}
				td{
					padding:.5rem;
					font-size:2rem;
					@include mq-down(md) {
						font-size:1.6rem;
					}
					.f-small{
						font-size:1.4rem;
					}
				}
				td:nth-of-type(1){
					white-space: nowrap;
				}
			}
			.c-feature{
				margin-top:5rem;
				text-align: center;
				position: absolute;
				bottom:10rem;
				left:0;
				padding:0 4.5rem;
				line-height: 2;
				@include mq-down(md) {
					bottom:5rem;
					padding:0 2rem;
				}
				img{
					width:70%;
					margin-bottom:1rem;
				}
				p{
					font-size:2rem;
					@include mq-down(md) {
						font-size:1.6rem;
					}
				}
			}
		}
	}
}

.p-comman__style__other{
	background-color: #F0F0E8;
	padding:5rem 0 3rem 0;
	margin-bottom:7rem;
	h3{
		text-align: center;
		letter-spacing: 4px;
		@include mq-down(md) {
			padding:0 1.5rem;
		}
	}
	.c-comman__style__other__contents{
		max-width:900px;
		margin:0 auto;
		margin-top:4rem;
		flex-wrap: wrap;
		.c-comman__style__other__item{
			color:white;
			padding:1rem 3rem;
			background-color: #C62828;
			margin:0 .5rem 2rem .5rem;
			letter-spacing: 3px;
			@include mq-down(md) {
				width:100%;
				text-align: center;
			}
		}
		@include mq-down(xl) {
			width:85%;		
		}
	}
}

.c-maintenance__about{
	padding:3rem 1rem;
	text-align: center;
	margin-bottom:10rem;
	background-color: #F0F0E8;
	border-radius: 20px;
	.c-maintenance__info{
		margin:4rem 0 0 2rem;
		@include mq-down(md) {	
			margin:0;
		}
	}
	img{
		width:30%;
		object-fit: contain;
		@include mq-down(md) {	
			width:80%;
		}
	}
}

.c-maintenance__about__detail{
	background-color: #F0F0E8;
	margin:13rem 0;
	padding-bottom:4rem;
	@include mq-down(md) {	
		margin:10rem 0;
	}
	.c-maintenance__info{
		margin:4rem 0 0 2rem;
		@include mq-down(md) {	
			margin:0;
		}
	}
	img{
		width:30%;
		object-fit: contain;
		@include mq-down(md) {	
			width:80%;
		}
	}
}