// ヘッダーレイアウト要素

.l-header {
	$r: &;
	position: fixed;
	width: 100%;
	left: 0;
	top: 0;

	.nav-sp-only {
		display: none;

		@include mq-down($gnav-breakpoint) {
			display: block;
		}
	}

	&.is-active {
		@include mq-up($gnav-breakpoint) {
			#{$r}__btnToggle {
				position: fixed;
				top: 0;
				right: 0;
			}
		}
	}

	&.is-fixed {
		//@include mq-up($gnav-breakpoint) {
		//	#{$r}__menu>ul>li.page_item_has_children>.children {
		//		top: 80px !important;
		//	}
		//
		//	#{$r}__btnNav,
		//	#{$r}__btnToggle {
		//		width: 80px;
		//		height: 80px;
		//	}
		//}
	}

	&__container {
		background-color: #ffffff;
	}

	&__site {
		padding: 0 30px 0 0;

		@include mq-down(md) {
			display: block !important;
			padding: 0 20px;
		}
	}

	&__logo {
		margin: 0 20px 0 30px;
		font-size: 0;
		line-height: 1;

		@include mq-down(md) {
			margin: 0;
		}

		img {
			width: 274px;

			@include mq-down(md) {
				width: 191px;
			}
		}
	}

	&__desc {
		position: relative;
		bottom: -5px;

		@include mq-down(md) {
			bottom: auto;
		}

		&Text1 {
			@include font-size(1.1rem);
			font-weight: 500;
			line-height: 1.8rem;
			white-space: nowrap;
		}

		&Text2 {
			@include font-size(1.1rem);
			font-weight: 500;
			line-height: 1.8rem;
			position: relative;
			padding-left: 20px;
			margin-left: 30px;
			display: none;
			white-space: nowrap;

			@include mq-up(xxl) {
				display: block;
			}

			@include mq-down(md) {
				display: none;
			}

			&:before {
				content: "";
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 1px;
				height: 41px;
				background-color: #b2b2b2;
			}
		}
	}

	&__phone {
		position: relative;
		display: block;
		text-align: right;
		margin: 10px 0 auto;

		@include mq-down($gnav-breakpoint) {
			display: none;
		}

		a {
			@include poppins;
			@include font-size(2.2rem);
			line-height: 3.3rem;
			letter-spacing: 1.1px;
			font-weight: 600;
			color: $color2;

			// pointer-events: none;
			@include mq-down($gnav-breakpoint) {}

			&:before {
				content: "";
				position: relative;
				display: inline-block;
				width: 14px;
				height: 20px;
				background: url(../img/icon/icon-smartphone.svg) no-repeat center/14px 20px;
				margin-right: 14px;
				top: 2px;
			}
		}
	}

	&__navBar {
		display: flex;
		height: 100%;
	}

	&__navInner {
		display: flex;
		flex-direction: column;
		height: 100%;

		@include mq-down($gnav-breakpoint) {
			overflow-y: scroll;
		}

		#{$r}__social {
			@include mq-up(md) {
				display: none;
			}
		}
	}

	&__navInnerBtn {
		display: none;

		@include mq-down($gnav-breakpoint) {
			display: block;
			flex-shrink: 0;
			background-color: #ffffff;
			margin-top: auto;
			padding: 40px 20px 36px;
		}

		.c-btn3 {
			text-align: center;

			&__link {
				width: 100%;
				max-width: 305px;
			}
		}

		.c-btn3+.c-btn3 {
			margin-top: 10px;
		}
	}

	&__navClose {
		display: none;

		@include mq-down($gnav-breakpoint) {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			width: 60px;
			height: 60px;
			background-color: $main;
			z-index: ($gnav-overlay-zIndex + 4);
			cursor: pointer;
		}

		&__icon {

			&:before,
			&:after {
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				margin: auto;
				width: 35px;
				height: 1px;
				background-color: #ffffff;
			}

			&:before {
				transform: rotate(45deg);
			}

			&:after {
				transform: rotate(-45deg);
			}
		}
	}

	&__nav2 {
		list-style: none;
		padding: 0;
		margin: 0;

		&>li {
			padding-bottom: 36px;
			border-bottom: 1px solid #b2b2b2;
			margin-bottom: 40px;
		}

		&Link {
			color: $color1;

			@include hover {
				color: $main;
			}
		}

		&Link2 {
			color: $color1;

			@include hover {
				color: $main;
			}
		}

		&Child {
			list-style: none;
			margin: 0;
			padding: 23px 0 0 30px;

			li+li {
				margin-top: 24px;
			}
		}

		&Eng {
			@include poppins;
			@include font-size(1.2rem);
			line-height: 1.5;
			letter-spacing: 1.8px;
			color: $main;
			text-transform: uppercase;
			margin-bottom: 13px;
		}

		&Jap {
			position: relative;
			@include font-size(2rem);
			font-weight: 500;
			line-height: 1.35;

			&:after {
				content: "";
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 16px;
				height: 11px;
				background: url(../img/icon/icon-arrow.svg) no-repeat center/16px 11px;
			}
		}

		&Jap2 {
			@include font-size(1.6rem);
			line-height: 1.375;
			font-weight: 500;
		}
	}

	&__menu {

		//position: relative;
		@include mq-down($gnav-breakpoint) {
			padding: 0 30px 0 20px !important;
			overflow-y: visible !important;
		}

		a {
			@include noto-sans;
			@include font-size(1.6rem);
			font-weight: 500 !important;
			line-height: 2.2rem;
			color: $color1;
			text-decoration: none;
			white-space: nowrap;

			@include hover {
				text-decoration: none;
				opacity: 1;
			}
		}

		.children {
			@include mq-up($gnav-breakpoint) {
				display: block !important;
			}
		}

		.page_item_has_children>a:after {
			@include mq-down($gnav-breakpoint) {
				content: "";
				position: relative;
				display: inline-block;
				width: 10px;
				height: 5px;
				background: url(../img/icon/icon-arrow-down.svg) no-repeat center/10px 5px;
				margin-left: 20px;
			}
		}

		.page_img_item>a {
			@include mq-up($gnav-breakpoint) {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding-right: 0 !important;
				padding-bottom: 8px !important;
				border-bottom: 2px solid #B2B2B2;
				width: 220px !important;

				&:after {
					display: none !important;
				}
			}
		}

		// Level 0
		&>ul {
			display: flex;

			@include mq-down($gnav-breakpoint) {
				display: block;
			}
		}

		&>ul>li {
			@include mq-down($gnav-breakpoint) {
				border-bottom: 1px solid $line-color;
			}
		}

		//& > ul > li.current_page_item > a:before,
		//& > ul > li.current_page_parent > a:before {
		//	opacity: 1;
		//}

		&>ul>li+li {
			@include margin-left(4rem);

			@include mq-down($gnav-breakpoint) {
				margin-left: 0 !important;
			}
		}

		&>ul>li>a {
			position: relative;
			padding-bottom: 13px;

			@include mq-down($gnav-breakpoint) {
				text-align: left !important;
				padding-top: 20px;
				padding-bottom: 20px;
			}

			@include mq-up($gnav-breakpoint) {
				&:before {
					content: "";
					position: absolute;
					width: 100%;
					height: 4px;
					background-color: $color2;
					transition: all 0.3s ease-in-out;
					opacity: 0;
					bottom: 0;
					left: 0;
				}
			}
		}

		// Level 1
		&>ul>li.page_item_has_children {
			//position: relative;

			//Level 2+
			.children {
				position: absolute;
				background-color: #ffffff;
				min-width: 150px;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
				left: 100%;
				top: 0;
				opacity: 0;
				visibility: hidden;
				pointer-events: none;
				transition: all 0.3s ease-in-out;

				@include mq-down($gnav-breakpoint) {
					display: none;
					transition: none !important;
					opacity: 1;
					visibility: visible;
					pointer-events: auto;
					box-shadow: none;
					position: relative;
					top: 0;
					left: 0;
					padding-left: 21px;
					min-width: auto;
					width: 100%;
				}
			}

			li.page_item_has_children:hover>.children {
				@include mq-up($gnav-breakpoint) {
					opacity: 1;
					visibility: visible;
					pointer-events: auto;
				}
			}
		}

		&>ul>li.page_item_has_children:hover>.children {
			@include mq-up($gnav-breakpoint) {
				opacity: 1;
				visibility: visible;
				pointer-events: auto;
			}
		}

		&>ul>li:hover>a::before {
			@include mq-up($gnav-breakpoint) {
				opacity: 1;
			}
		}

		// Children level 2
		&>ul>li.page_item_has_children>.children {
			@include mq-up($gnav-breakpoint) {
				position: fixed;
				left: 0;
				top: 100px;
				width: 100%;
				background-color: rgba(#000000, 0.8);
			}

			@include mq-down($gnav-breakpoint) {
				padding-bottom: 12px;
			}

			li.current_page_item>a {
				color: $main;
			}

			a {
				text-align: left;

				@include mq-up($gnav-breakpoint) {
					position: relative;
					font-weight: bold;
					line-height: 1.6875;
					color: #ffffff;
					padding: 0 20px 0 0;
					width: 200px;
					max-width: 100%;

					&:hover {
						color: $main;
					}

					&:after {
						content: "";
						position: absolute;
						right: 0;
						top: 50%;
						transform: translateY(-50%);
						display: inline-block;
						width: 16px;
						height: 11px;
						background: url(../img/icon/icon-arrow.svg) no-repeat center/16px 11px;
					}
				}

				@include mq-down($gnav-breakpoint) {
					padding: 8px 8px 7px;
				}
			}
		}

		// Children level 2
		&>ul>li.page_item_has_children>.children {

			.children_inner {
				@include mq-up($gnav-breakpoint) {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					max-width: 1240px;
					margin: 0 auto;
					padding: 40px 20px;
				}
			}

			.children_list {
				@include mq-up($gnav-breakpoint) {
					display: flex;
					justify-content: flex-end;
					flex-wrap: wrap;
					gap: 20px 30px;
					margin-left: auto;
				}
			}

			.children_layout1 {
				@include mq-up($gnav-breakpoint) {
					display: flex;
					gap: 40px;
				}

				.is-service-list {
					@include mq-up($gnav-breakpoint) {
						position: relative;
						top: -5px;
						flex-direction: column;
						gap: 10px 20px;
					}
				}
			}

			.children_img1 {
				display: none;

				@include mq-up($gnav-breakpoint) {
					display: block;
					margin-bottom: 8px;
				}
			}

			.children_title {
				position: relative;
				align-self: flex-start;
				flex-shrink: 0;
				padding-right: 50px;
				top: 2px;

				@include mq-down($gnav-breakpoint) {
					display: none;
				}
			}

			.children_title_eng {
				@include poppins;
				@include font-size(1.2rem);
				letter-spacing: 0.6px;
				line-height: 1.2;
				display: block;
				color: $main;
				margin-bottom: 15px;
			}

			.children_title_jap {
				@include font-size(1.8rem);
				display: block;
				color: #ffffff;
				line-height: 1.3;
				font-weight: 500;
			}
		}

		&>ul>li.page_item_has_children .children_list>li:first-child>a {
			@include mq-down($gnav-breakpoint) {
				padding-top: 0 !important;
			}
		}
	}

	&__btn {
		@include margin-left(3rem);
	}

	&__btnContact {
		width: 212px;

		@include mq-down($gnav-breakpoint) {
			width: 60px;
		}

		a {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			background-color: $color1;
			color: #ffffff;
			@include font-size(1.6rem);
			font-weight: bold;
			line-height: 2.2rem;
			height: 100%;
			position: relative;
			padding: 10px;

			@include hover {
				background-color: rgba($color1, 0.8);
			}

			@include mq-down($gnav-breakpoint) {
				font-size: 0;
				text-indent: -9999;
			}

			&:before {
				content: "";
				position: relative;
				display: inline-block;
				width: 17px;
				height: 14px;
				background: url(../img/icon/icon-mail.svg) no-repeat center / 17px 14px;
				margin-right: 18px;
				bottom: -1px;

				@include mq-down($gnav-breakpoint) {
					margin-right: 0;
					position: absolute;
					bottom: auto;
					top: 50%;
					transform: translate(0, -50%);
				}
			}
		}
	}

	&__btnToggle,
	&__btnNav {
		flex-shrink: 0;
		width: 100px;
		height: 100%;
		background-color: $color2;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		cursor: pointer;

		@include mq-up($gnav-breakpoint) {
			width: 100px;
			height: 100px;
		}

		@include mq-down($gnav-breakpoint) {
			width: $gnav-navbar-height--sp;
			height: $gnav-navbar-height--sp;
			pointer-events: auto;
		}

		@include hover {
			background-color: rgba($color2, 0.8);
		}
	}

	&__btnToggle {
		@include mq-up($gnav-breakpoint) {
			z-index: ($gnav-overlay-zIndex + 4);
		}
	}

	&__pc {
		@include mq-down($gnav-breakpoint) {
			display: none !important;
		}

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 124px;
			height: 100%;
			background: url(../img/common/nav-logo.svg) no-repeat top left/auto 100%;
			z-index: 0;
		}

		&Bg {
			position: absolute;
			right: 0;
			top: 0;
			z-index: 0;
			width: auto;
			height: 100%;

			img {
				width: auto;
				height: 100%;
			}
		}

		&Inner {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
		}

		&Content {
			width: 100%;
			height: 100%;
			position: relative;
			overflow-x: hidden;
			overflow-y: scroll;
		}

		&Rows {
			position: relative;
			width: 100%;
			max-width: 910px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			gap: 0 65px;
			z-index: 5;
			padding: 40px 0;
		}

		&Col {
			width: calc((100% - 65px) / 3);
		}

		&Btn {
			padding-bottom: 40px;

			.c-btn3 {
				width: 100%;

				&__link {
					width: 100%;

					@media screen and (max-width: 1500px) {
						box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
					}
				}
			}

			.c-btn3+.c-btn3 {
				margin-top: 30px;
			}
		}
	}

	&__social {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		@include mq-down($gnav-breakpoint) {
			padding: 0 20px 33px;
			justify-content: center;
		}

		&__ttl {
			@include font-size(1.4rem);
			line-height: 1.5;
			color: #373737;
			letter-spacing: 0.7px;
			@include poppins();
		}

		&__list {
			list-style: none;
			margin: 0;
			flex: 1;
			display: flex;
			flex-wrap: wrap;
			padding: 0 0 0 16px;

			@include mq-down($gnav-breakpoint) {
				flex: none;
				max-width: 176px;
				width: 59%;
				justify-content: space-between;
			}
		}

		&__item {
			width: 40px;

			@include mq-down($gnav-breakpoint) {
				width: 25%;
			}

			&:not(:last-child) {
				margin: 0 20px 0 0;

				@include mq-down($gnav-breakpoint) {
					margin: 0;
				}
			}

			a {
				display: block;
				width: 100%;

				img {
					width: 100%;
				}
			}
		}
	}
}