// Color system
// ==========================================================================
// ユニークカラー
$color1: #1C1C1C;
$color2: #C62828;
$color3: #F0F0E8;
$color4: #EAEAEA;
$color5: #727272;

// must color
$main: $color2;
$accent: #CB9A8D;
$base: #fff; // 薄いベースカラーを設定
$text-color: $color1; // テキストカラー
$text-color-main:$color5;
$text-color-sub: #767676; // テキストカラーサブ
$line-color: #B2B2B2; // ラインカラー

// bootstrap-colors
$primary: $main;
$secondary: $text-color-sub;
$success: $primary !default;
$info: $primary !default;
$warning: $primary;
$danger: $primary;
$light: $line-color;
$dark: $text-color;


// 背景色設定
// ==========================================================================
$body-bg: #fff; // デフォルト背景色

// リンク設定
// ==========================================================================
$link-color: darken($primary, 20%); // リンクカラー
$link-decoration: underline;
$link-shade-percentage: 20%;
$link-hover-color: darken($link-color, $link-shade-percentage);

// ローディング設定
// ==========================================================================
$loding-spinner: $primary; // スピナーの色
$loding-bg: #fff; // ローディング画面の背景色

// Typography
// ==========================================================================
// default font
$body-color: $text-color; // 基本のフォントカラー
$font-family-base: "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif; // ベースとなるフォント
$web-font: "Noto Sans JP", sans-serif;

$font-size-root: 62.5%;
$font-size-base: 1.7rem; // デフォルトフォントサイズ - remで指定する
$font-size-xs: $font-size-base * .75; // 12px
$font-size-sm: $font-size-base * .875; // 14px
$font-size-lg: $font-size-base * 1.125; // 18px
$font-weight-base: 400; // デフォルトフォントの太さ
$letter-spacing: .04em; // デフォルトフォント字間
$line-height-base: 1.6; // デフォルトフォントの行間

$editor-font-size: 16px; // 投稿エディター用デフォルトフォントサイズ

// header font
$headings-font-family: null;
$headings-color: $text-color; // 見出しのテキストカラー
$headings-font-weight: 500; // 見出しのフォントの太さ
$headings-line-height: 1.6; // 見出しの行間
$headings-letter-spacing: .05em; // 見出しの字間 （reboot.scss追記）

$h1-font-size: $font-size-base * 2.5; // 40px
$h2-font-size: $font-size-base * 2; // 
$h3-font-size: $font-size-base * 1.75; // 28px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base; // 16px

$font-sizes: (
	h1: $h1-font-size,
	h2: $h2-font-size,
	h3: $h3-font-size,
	h4: $h4-font-size,
	h5: $h5-font-size,
	h6: $h6-font-size
);

// Spacing
// ==========================================================================
$spacer: 0.8rem;
$spacers: (
	0: 0,
	1: $spacer, //8
	2: $spacer * 2, //16
	3: $spacer * 3, //24
	4: $spacer * 4, //32
	5: $spacer * 5, //40
	8: $spacer * 8, //64
	10: $spacer * 10, //80
	15: $spacer * 15, //120
);

// Grid columns
// ==========================================================================
// bootstrapのgridが変わりますよ！
$grid-columns: 12;
$grid-gutter-width: 32px;

// container-width
// ==========================================================================
// bootstrapのgridが変わりますよ！
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	// xxl: 1444px
	// xxl: 1520px
) !default;

// Breakpoint
// ==========================================================================
// bootstrapのbreakpointが変わりますよ！
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1140px,
	// xxl: 1444px
	// xxl: 1520px
) !default;

// Header Hight Settings
// ==========================================================================
$heder-hight: 100px; // ヘッダーの高さ - PC
$heder-hight--fixed: 100px; // クローンヘッダーの高さ - PC
$heder-hight--sp: 60px; // ヘッダーの高さ - SP

// gnav Settings
// ==========================================================================
$gnav-breakpoint: xl; // ブレイクポイント位置指定、$grid-breakpointsから選択
$gnav-width: 32rem; // SP - 開いた時の全体メニューの幅
$gnav-navbar-height: $heder-hight; // ナビバーの高さ
$gnav-navbar-height--sp: $heder-hight--sp; // ナビバーの高さ
$gnav-overlay-zIndex: 100;
$gnav-transitionDuration: .3s;
$gnav-transitionFunction: ease-in-out;
$gnav-top-nav-maxHeight: 100%;

// メニュー
$gnav-menu-gutter: 20px; // PC時のメニューの間の左右の余白
$gnav-menu-color: $text-color; // メニュー項目の色
$gnav-menu-color--hover: $primary; // メニュー項目のホバー色
$gnav-menu-fontsize: 1.6rem; // メニューのフォントサイズ
$gnav-menu-fontfamily: $web-font; // メニューのフォント
$gnav-menu-fontweight: 400; //メニューの太さ
$gnav-menu-order: column; //メニューとタイトル属性の順番 column-reverse columnなどね
$gnav-menu-title-fontsize: 1.2rem; // メニューのタイトル属性のフォントサイズ
$gnav-menu-title-fontwight: 400; // メニューのタイトル属性のフォントの太さ
// メニュー:ホバー
$gnav-menu-hoverunderline--color: $primary; // メニューのタイトル属性のフォントの太さ
$gnav-menu-hoverunderline--width: 2px; // メニューのタイトル属性のフォントの太さ
$gnav-menu-hoverunderline--type: slide; // メニュー下線の出し方 slide or fade
$gnav-menu-hoverunderline--dir: center; // typeでslideを指定した場合はleft-right, left-in-right, right-left, right-in-left, center

// メニュー スマホ時
$gnav-header-bg: transparent; // スマホ時のヘッダー全体の背景色
$gnav-header-bg--fixed: #fff; // スマホ時のヘッダー全体の背景色
$gnav-header-bg--fixed__shadow: 0 2px 10px rgba(0, 0, 0, .1);
//$gnav-menu-bg: linear-gradient(0deg, rgba(234,170,115,1) 0%, rgba(234,220,115,1) 100%); //スマホメニューの背景色
$gnav-menu-bg: #ffffff;
$gnav-menu-under-bg: rgba(0, 0, 0, .2); //スマホメニュー表示時の全体に引いてある背景色

// サブメニュー
$gnav-submenu-width: 18rem; // SP - 開いた時の全体メニューの幅
$gnav-submenu-bg: #fff; // サブメニューの背景色 - PC
$gnav-submenu-bg__hover: $primary; // サブメニューの背景色のホバー背景色 - PC
$gnav-submenu-bg--sp: #fff; // サブメニューの背景色 - PC
$gnav-submenu-color: $text-color; // サブメニューのフォントカラー - PC
$gnav-submenu-border: none; // サブメニューのフォントカラー - PC

// トグルメニュー画面設定
// $gnav-menu-background: linear-gradient(0deg, rgba(234,170,115,0.85) 0%, rgba(234,220,115,0.85) 100%); //トグルメニュー画面の背景 //rgba(0, 0, 0, .5) 初期値


// トグル設定
$gnav-toggle-width: 35px; // トグルメニューのバーの横幅
$gnav-toggle-gutter: 8px; // トグルメニューのバーの間隔
$gnav-toggle-wight: 1px; // トグルメニューのバーの太さ
$gnav-toggle-radius: 8px; // トグルメニューのバーの角丸（3px以下はよくわからん）
$gnav-toggle-color: #ffffff; // トグルメニューのバーの色
$gnav-toggle-fontfamily: $web-font; // トグルメニューの開閉文字のフォント
$gnav-toggle-right: 3%; //トグルメニューの右位置
$gnav-toggle-top: 3%; //トグルメニューの上位置
$gnav-toggle-menuposition: 3px; //トグルメニュー文字の位置
$gnav-toggle-menucolor: $text-color; //トグルメニュー文字色
