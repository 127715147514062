.js-gnav {
	z-index: ($gnav-overlay-zIndex + 2);
	top: 0;
	width: 100%;
	height: $gnav-navbar-height--sp;

	&.no-anim {
		animation: none !important;
	}

	@include mq-down($gnav-breakpoint) {
		&.is-fixed {
			position: fixed;
			background: $gnav-header-bg--fixed;
			box-shadow: $gnav-header-bg--fixed__shadow;
			//animation: header-fade-in .4s ease-in-out;
		}

		// SP active overlay
		&.is-active {
			&::after {
				content: '';
				position: fixed;
				z-index: $gnav-overlay-zIndex;
				top: 0;
				left: 0;
				display: block;
				width: 100%;
				height: 100%;
				background-color: $gnav-menu-under-bg;
			}
		}

		&:after {
			transition: background-color 0.3s ease-in-out;
		}
	}

	@include mq-up($gnav-breakpoint) {
		height: $heder-hight;
		&.is-fixed {
			height: $heder-hight--fixed;
			position: fixed;
			transform: translateY(0);
			z-index: 990;

			//animation-name: header-fade-in;
			//animation-duration: 0.4s; //アニメーション時間
			//animation-timing-function: ease-in-out; //アニメーションさせるイージング
			//animation-delay: 0s; //アニメーション開始させる時間
			//animation-iteration-count: 1; //繰り返し回数
			//animation-direction: normal; //往復処理をするかどうか
			//animation-fill-mode: forwards;

			background: $gnav-header-bg--fixed;
			box-shadow: $gnav-header-bg--fixed__shadow;
		}
	}

	@keyframes header-fade-in {
		0% {
			opacity: 0;
			transform: translateY(-100%);
		}
		1% {
			opacity: 1;
		}
		100% {
			transform: translateY(0);
			opacity: 1;
		}
	}

	&__container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;
	}

}

// 固定ヘッダー分余白
// #page {
// 	padding-top: $gnav-navbar-height--sp;
// 	@include mq-up($gnav-breakpoint) {
// 		padding-top: $gnav-navbar-height;
// 	}
// }
