.hamburger {
	position: fixed;
	display: none;
	bottom: 60px;
	right: 10px;
	width: 50px;
	height: 50px;
	cursor: pointer;
	z-index: 9999;
	background:white;
	border-radius: 50px;
	@include mq-down(md) {
		display: block;
	}
	&::after{
		content:"メニュー";
		position: absolute;
		bottom:0;
		font-size:8px;
		font-weight: bold;
		color:rgba(198,40,40,1);
		left:6px;
		bottom:6px;
	}
	.line{
		position: absolute;
		top: 0;
		right: 11px;
		width: 27px;
		height: 4px;
		background:rgba(198,40,40,1);
		text-align: center;
		border-radius: 10px;
	}
	.line01{
		top: 6px;
		transition: 0.4s ease;
	}
	.line02{
		top: 14px;
		transition: 0.4s ease;
	}
	.line03{
		top: 22px;
		transition: 0.4s ease;
	}
}

.v-line01 {
	transform: translateY(6px) rotate(-45deg);
	transition: 0.4s ease;
}

.v-line02 {
	transition: 0.4s ease;
	opacity: 0;
}

.v-line03 {
	transform: translateY(-10px) rotate(45deg);
	transition: 0.4s ease;
}

.menu-enter-active,
.menu-leave-active {
	transition: opacity 0.4s;
}

.menu-enter,
.menu-leave-to {
	opacity: 0;
}

.menu {
	background-color: rgba(255, 255, 255, 1);
	z-index: 8;
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	right: 0;
}