// ナビゲーション系

.c-nav {
	margin-bottom: 40px;

	// blogのカテゴリー表示ナビ
	>ul {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		.cat-item {

			a {
				text-decoration: none;
				transition: 0.2s ease-out;
			}

		}

		.current-cat {
			a {
				color: $main;
				font-weight: bold;
			}
		}

	}

	&-layout {
		&--a {
			ul {

				.cat-item {
					padding: 0 12px 0 0;

					&::after {
						content: "/";
						display: inline-block;
						padding: 0 0 0 6px;
						color: $line-color;
					}

					&:last-child {
						&::after {
							display: none;
						}
					}

					a {
						color: $line-color;

						&:hover {
							border-bottom: 2px solid $main;
						}
					}
				}

				.current-cat {
					a {
						color: $text-color;
						font-weight: bold;
					}
				}
			}

		}

		&--b {
			display: flex;
			flex-wrap: nowrap;

			p {
				width: auto;
				min-width: 6em;
				margin: 0;
				font-family: $web-font;
				font-size: 14px;
				padding: 4px 0 4px 0;
				font-weight: 700;

				@include mq-down(sm) {
					font-size: 12px;
				}
			}

			ul {
				min-width: 0;
				flex-grow: 1;

				.cat-item {
					margin: 0 12px 12px 0;
					display: flex;

					@include mq-down(sm) {
						margin: 0 6px 12px 0;
					}

					a {
						background-color: $line-color;
						color: $white;
						padding: 4px 12px;
						border-radius: 20px;
						font-size: 14px;
						@include hover_opacity();

						@include mq-down(sm) {
							padding: 2px 8px;
							font-size: 12px;
						}

						&:hover {
							background-color: $main;
						}
					}
				}

				.current-cat {
					a {
						background-color: $main;
						font-weight: bold;
					}
				}
			}
		}

		&--c {
			display: flex;
			flex-wrap: nowrap;

			p {
				width: auto;
				min-width: 6em;
				margin: 0;
				font-family: $web-font;
				font-size: 14px;
				padding: 4px 0 4px 0;
				font-weight: 700;

				@include mq-down(sm) {
					font-size: 12px;
				}
			}

			ul {
				min-width: 0;
				flex-grow: 1;

				.cat-item {
					margin: 0 12px 12px 0;
					display: flex;

					@include mq-down(sm) {
						margin: 0 6px 12px 0;
					}

					a {
						background-color: $line-color;
						color: $white;
						padding: 4px 12px;
						font-size: 14px;
						@include hover_opacity();

						@include mq-down(sm) {
							padding: 2px 8px;
							font-size: 12px;
						}

						&:hover {
							background-color: $main;
						}
					}
				}

				.current-cat {
					a {
						background-color: $main;
						font-weight: bold;
					}
				}
			}
		}
	}

}
