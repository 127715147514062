.l-container {
	max-width: 1520px;
	@include mq-down(md) {
		max-width: 100%;
		padding: 0 10px;
	}
}

.text-center {
	text-align: center;
}

.text-end{
	text-align: end;
}

.w-md-100{
	@include mq-down(md) {
		width:100%;
	}
}

.m-md-auto{
	@include mq-down(md) {
		margin:0 auto;
	}
}

.bg-white{
	background-color:white;
}

.d-inline-b{
	display: inline-block;
}
.d-block{
	display: block;
}

.d-none-lg{
	@include mq-down(lg) {
		display: none;
	}
}

.d-none-md{
	@include mq-down(md) {
		display: none;
	}
}
.d-block-md{
	display: none;
	@include mq-down(md) {
		display: block;
	}
}
.md-margin-0{
	@include mq-down(md) {
		margin:0 auto;
	}
}
.fs-12{
	font-size:1.2rem;
}
.pr-2{
	padding-right: 2.0rem;
}

.b-radius-20{
	border-radius: 20px;
}