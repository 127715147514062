.c-service__img__00 {
    text-align: center;
    @include contents-under-mb();
    img {
        width: 100%;
        aspect-ratio: 11 / 5;
        object-fit: cover;
    }
}

.c-service__img__01 {
    width: 100%;
    @include text-under-mb();
    @include mq-down(md) {
        width:100%
    }
    img {
        width: 100%;
        object-fit: cover;
    }
}

.c-service__img__02 {
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    margin-bottom: 4.0rem;
    text-align: end;

    img {
        width: 70%;
        aspect-ratio: 11 / 5;
        object-fit: cover;
    }
}

.c-service__img__03 {
    margin: 0 calc(50% - 50vw);
    width: 100vw;
    margin-bottom: 4.0rem;

    img {
        width: 55%;
        aspect-ratio: 11 / 5;
        object-fit: cover;
    }
}

.c-service__img__04 {
    position: relative;
    @include contents-under-mb();
    .c-sevice__img__04__s {
        width: 60%;

        @include mq-down(lg) {
            // width: 55vw;
        }

        @include mq-down(md) {
            width: 100%;
            margin: auto;
            margin-bottom: 3rem;
        }
    }

    img {
        width: 100%;
        object-fit: cover;
    }

    .c-btn2 {
        position: absolute;
        top: 50%;
        left: 80%;
        transform: translateY(-50%) translateX(-80%);

        @include mq-down(lg) {
            left: 78%;
            transform: translateY(-50%) translateX(-70%);
        }

        @include mq-down(md) {
            position: static;
            top: 0;
            left: 0;
            text-align: center;
            transform: none;
        }

    }
}

.c-service__info__01 {
    @include contents-under-mb();

    h2 {
        margin-bottom: 4rem;
        @include main-headline();
        @include mq-down(md) {
            margin-bottom: 2rem;
        }
    }

    p {
        @include sub-headline();
        @include mq-down(md) {
            font-size: 1.6rem;
        }
    }
}

.c-service__title__02 {
    text-align: center;
    margin-bottom: 10rem;
    h2 {
        color: $color5;
    }
}

.c-service__arrow__bottom{
    @include mq-down(md) {
        img{
            width:50%;
        }
    }

}