#ez-toc-container {
	background: $white;
	border: 1px solid #707070;
	position: relative;
	padding: 30px 60px 50px;
	margin-bottom: 60px;
	margin-top:60px;
	width:100%;

	@include mq-down(sm) {
		padding: 20px 24px 40px;
	}

	.ez-toc-title-container {
		.ez-toc-title {
			position: absolute;
			background: #fff;
			left: 0;
			right: 0;
			top: -14px;
			margin: auto;
			display: inherit;
			padding: 0 24px;
			font-weight: 700;
			font-size: 20px;
		}

	}

	.ez-toc-list {
		li{
			padding-top:1rem;
		}
		a {
			color: $text-color;
		}
	}
}
