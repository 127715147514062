// コンテンツレイアウト01
.contents__layout_01 {
    margin-bottom: 100px;

    @include mq-down(md) {
        margin-bottom: 50px;
    }

    .contents__layout_01__item {
        width: 18%;
        text-align: center;
        position: relative;
        background-color: #F0F0E8;
        border-radius: 100px;
        aspect-ratio: 1 / 1;
        margin: 0 1% 4rem 1%;

        @include mq-down(md) {
            width: 47%;
        }

        img {
            width: 100%;
            object-fit: cover;
            padding: 1rem;
        }

        p {
            padding: 1rem;
            position: absolute;
            top: 50%;
            left: 50%;
            color: #1C1C1C;
            transform: translateY(-50%) translateX(-50%);

            @include mq-down(md) {
                padding: 0;
            }
        }

        .contents__layout_01__item__text {
            white-space: nowrap
        }
    }
}

.col_5 {
    width: 20%;
    text-align: center;
    color: #727272;

    @include mq-down(lg) {
        width: 33.3%;
    }
}

// コンテンツレイアウト02
.contents__layout_02 {
    flex-wrap: wrap;
    margin-bottom: 90px;

    .contents__layout_02__item {
        width: 50%;
        text-align: center;
        font-size: 2.8rem;
        border: 1px solid rgba(112, 112, 112, 0.2);
        margin: 0 -.5px -1px;
        display: table;

        p {
            display: table-cell;
            vertical-align: middle;

            a {
                display: block;
                height: 100px;
                line-height: 100px;
                color: #1C1C1C;
                @include hover_opacity();
            }
        }

        @include mq-down(lg) {
            font-size: 1.8rem;
        }

        @include mq-down(md) {
            width: 50%;
            padding: 0 15px;
            font-size: 1.6rem;
            height: 80px;
        }
    }
}


// コンテンツレイアウト03
.contents__layout_03 {
    flex-wrap: wrap;
    margin-bottom: 100px;

    .contents__layout_03__item {
        box-shadow: 30px 30px 80px -10px rgba(0, 0, 0, 0.16);
        width: 49%;
        text-align: center;
        background-color: white;
        height: 350px;
        padding: 0 70px;
        margin: .1%;

        @include mq-down(lg) {
            padding: 0 30px;
        }

        h3 {
            font-size: 2.8rem;
            padding-top: 4rem;

            @include mq-down(lg) {
                padding-top: 2rem;
            }

            @include mq-down(md) {
                font-size: 2rem;
            }
        }

        p {
            font-size: 1.8rem;
            text-align: left;
            padding-top: 20px;

            @include mq-down(lg) {
                padding-top: 1px;
            }

            @include mq-down(md) {
                font-size: 1.6rem;
            }
        }

        @include mq-down(md) {
            width: 100%;
            height: auto;
            margin-top: 15px;
            padding-bottom: 30px;
        }
    }
}

// コンテンツレイアウト04
.contents__layout_04 {
    width: 100%;
    margin-bottom: 80px;

    tr {
        td:nth-of-type(1) {
            width: 40%;
            padding: 35px 0;
            border-top: 5px solid #CDD6DD;

            @include mq-down(md) {
                padding: 20px 0;
            }

            h3 {
                color: #3B4043;
                font-size: 2.4rem;

                @include mq-down(md) {
                    font-size: 2rem;
                }
            }

            p {
                color: #3B4043;
                font-size: 1.6rem;
                font-weight: bold;
                padding-top: 20px;
                color: #C62828;

                @include mq-down(md) {
                    font-size: 1.2rem;
                    padding-top: 10px;
                }
            }
        }

        td:nth-of-type(2) {
            width: 60%;
            border-top: 1px solid #CDD6DD;
            color: #3B4043;
            padding-left: 20px;

            @include mq-down(md) {
                font-size: 1.2rem;
            }
        }
    }
}