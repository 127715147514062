.single-seminar__title {
	margin-bottom: 70px;
	.title__en{
		color:#C62828;
	}
}

.seminar__theme,.seminar__point {
	width: 100%;
	margin-bottom: 90px;

	h3 {
		color: #3B4043;
		font-weight: lighter;
	}
	.red{
		color:#C62828;
		font-weight: bold;
	}

	table {
		margin-top: 40px;
		width: 100%;

		tr {
			td:nth-of-type(1) {
				width: 40%;
				border-top: 5px solid #CDD6DD;
				line-height: 1;
			}

			td:nth-of-type(2) {
				width: 60%;
				padding-left: 20px;
				border-top: 1px solid #CDD6DD;
				padding: 35px 0 35px 20px;
			}
		}
	}
}

.seminar__overview {
	h3 {
		color: #3B4043;
		font-weight: 100;
	}

	p {
		color: #3B4043;
		margin-top: 30px;
	}
	.red{
		color:#C62828;
	}

	margin-bottom:50px;
}

.c-seminar__single__info {
	margin-bottom: 50px;
	.c-seminar__single__item {
		background-color: #F0F0E8;
		@include contents-box-shadow();
		padding: 30px 50px;

		@include mq-down(md) {
			padding: 15px 25px;
		}
	}

	dl {
		display: flex;
		table-layout: auto;

		.delimiter {
			padding: 0 10px;
		}

		dt,
		dd {
			font-size: 24px;
			-moz-text-align-last: justify;
			text-align-last: justify;
			text-justify: inter-ideograph;

			@include mq-down(md) {
				font-size: 1.8rem;
			}
		}

		dt {
			text-align: justify;
			width: 100px;
		}
	}
}

.seminar__single__img {
	margin-bottom: 100px;
	text-align: center;
	img {
		width: 80%;
	}
}

.seminar__signle__collabo{
	margin-bottom:10rem;
	.c-collabo__contents{
		.c-collabo__item{
			width:30%;
			@include mq-down(lg) {
				width:100%;
				margin-bottom:100px;
			}
			h4{
				color:#3B4043;
				padding-bottom:1rem;
				@include mq-down(sm) {
					font-size:2.2rem;
					
				}
			}
			p{
				margin-bottom:1rem;
			}
			.c-collabo__img{
				img{
					width:100%;
					object-fit: cover;
					aspect-ratio: 5 / 6;
				}
			}
			.c-collabo__logo{
				margin:20px 0;
				text-align: center;
				position: relative;
				height: 100px;
				img{
					position: absolute;
					top: 50%;
					left: 50%;
					max-height: 100%;
					transform: translateY(-50%) translateX(-50%);
				}
			}
			.c-collabo__detail{
				font-size:1.6rem;
				color:#727272;
			}
		}
		.c-collabo__item:nth-of-type(1) {
			position: relative;
			&::after{
				content:"×";
				position: absolute;
				top:25%;
				left:150%;
				font-size:100px;
				@include mq-down(lg) {
					left: 50%;
					transform: translateX(-50%);				 
					top:auto;
					font-size:50px;
				}
			}
		}
	}
}

.c-solo__seminar{
	.c-solo__img{
		width:40%;
		object-fit: cover;
		padding-right:8rem;
		@include mq-down(md) {
			width:100%;
			padding-right:0;
			padding-bottom:2rem;
		}
	}
	.c-solo__item{
		width:60%;
		@include mq-down(md) {
			width:100%
		}
		.c-solo__logo{
			padding-bottom:4rem;
			@include mq-down(md) {
				padding-bottom:2rem;
			}
		}
		.c-solo__detail{
			padding-top:4rem;
			@include mq-down(md) {
				padding-bottom:2rem;
			}
		}
	}
}


.notes{
	margin-bottom:70px;
	color:#707070;
	font-size:2.0rem;
}

.single__seminar__form{
	.red{
		color:#C62828;
	}
	margin-bottom:100px;
	#mw_wp_form_mw-wp-form-11408{
		margin-top:100px;
	}
}