.c-loop-post{
    border-bottom:1px solid #f2f2f2;
	padding-bottom:25px;
	margin-bottom:25px;
	@include mq-down(lg) {
		padding-bottom:10px;
		margin-bottom:25px;
	  }
	.c-loop-post__img{
		.post-thumbnail{
			width:180px;
			object-fit: cover;
			@include mq-down(lg) {
				width:124px;
			  }
			img{
				width:100%;
				height: 100px;
				border-radius: 10px;
				object-fit: cover;
				@include mq-down(lg) {
					height: 70px;
				}
			}
		}
	}
	.c-loop-post__body{
		margin:-5px 0 0 30px;
		@include mq-down(sm) {
			margin:-5px 0 0 15px;
		}
		.c-loop-post__title{
			font-size:18px;
			@include mq-down(sm) {
				font-size:14px;
			}
		}
		.c-meta-date{
			font-size:12px;
			font-family: 'Montserrat', sans-serif;
			color:$main;
			@include mq-down(lg) {
				font-size: 10px;
			}
		}
		.c-meta-category{
			font-size:12px;
			color:$main;
			@include mq-down(lg) {
				font-size: 10px;
			}
			a{
				display: block;
				background-color:$white;
				border-radius: 13px;
				padding:3px 14px;
				margin-bottom:5px;
			}
		}
	}
}



.c-loop-new{
    padding-left:10px;
    color:$main;
  }





//投稿個別レイアウト
// .c-loop {

// 	// NEWマーク
// 	&-new {
// 		@include font-size(1.2rem);
// 		background: $primary;
// 		color: #fff;
// 		width: 40px;
// 		height: 40px;
// 		border-radius: 50px;
// 		display: flex;
// 		align-items: center;
// 		justify-content: center;
// 	}

// 	// ポスト設定
// 	&--post {
// 		position: relative;
// 		margin-bottom: $grid-gutter-width;

// 		a {
// 			text-decoration: none;
// 		}

// 		.c-loop-new {
// 			position: absolute;
// 			top: -20px;
// 			right: 0;
// 		}

// 		&__img {
// 			border: 1px solid $primary;
// 			position: relative;
// 			cursor: pointer;
// 			overflow: hidden;

// 			&::after {
// 				content: 'READ MORE';
// 				font-family: $web-font;
// 				@include font-size(1.4rem);
// 				color: #fff;
// 				display: flex;
// 				align-items: center;
// 				justify-content: center;
// 				width: 100%;
// 				height: 100%;
// 				background: rgba(0, 0, 0, .6);
// 				position: absolute;
// 				top: 0;
// 				left: 0;
// 				transition: all .3s ease-in;
// 				opacity: 0;
// 			}

// 			&:hover {
// 				img {
// 					transform: scale(1.2, 1.2);
// 				}

// 				&::after {
// 					opacity: 1;
// 				}
// 			}
// 		}

// 		&__title {
// 			margin-top: 1em;
// 			color: $text-color;
// 			@include font-size(1.6rem);
// 			@include normal_transition();
// 			@include hover_opacity();

// 			a {
// 				color: $text-color;
// 			}
// 		}

// 		&__excerpt {
// 			@include font-size(1.2rem);
// 			line-height: 1.7;
// 		}

// 		// リストスタイル
// 		&--list {
// 			display: flex;
// 			align-items: flex-start;
// 			padding: 1.5em 0;
// 			border-bottom: 1px solid $line-color;
// 			position: relative;
// 			margin-bottom: 0;

// 			.c-loop-post__title {
// 				margin: 0 0 1em;
// 				color: $text-color;
// 			}

// 			.c-loop-new {
// 				position: absolute;
// 				top: 10px;
// 				left: 0;
// 			}

// 			.c-loop-post__img {
// 				margin-bottom: 0;
// 				.post-thumbnail{
// 					width:180px;
// 					img{
// 						width:100%;
// 					}
// 				}
// 			}

// 			.c-loop-post__body {
// 				padding: 0 24px;
// 			}
// 		}
// 	}
// }

// --------------------------------
// 投稿テンプレのデザイン
// --------------------------------
article {
	&:first-of-type {

		// layout-a
		.c-loop--post--layout--a {
			border-top: 1px solid $line-color;
		}

		// layout-c
		.c-loop--post--layout--c {
			border-top: 1px solid $line-color;
		}
	}
}

.c-loop--post--layout {
	// layout-a
	&--a {
		padding: 40px 0;
		margin: 0;
		border-bottom: 1px solid $line-color;
		.c-loop--post__date {
			text-align: center;
			// font-family: $font-hiragino;		
			&__day {
				font-size: 40px;
				line-height: 40px;
				margin-bottom: 0;
				letter-spacing: 0px;
			}

			&--bottom {
				display: inline-flex;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;
				position: relative;

				@include mq-down(sm) {
					&::after {
						content: "";
						width: 100%;
						height: 1px;
						position: absolute;
						background: $text-color;
						bottom: 12px;
					}
				}

				.c-loop--post__date__year {
					&:after {
						content: "/";
						display: inline-block;
						margin: 0 6px 0 2px;
					}
				}

				p {
					font-size: 14px;
					font-weight: bold;
				}
			}

		}

		.c-loop--post__body {}

		.c-loop--post__title {
			margin: 0 0 20px;
			color:#3B4043;

			* {
				font-size: 30px;
				letter-spacing: 2px;
				color: $text-color;
			}

			a {
				@include hover_opacity();
			}
		}

		.c-loop--post__excerpt {
			* {
				font-size: 14px;
				letter-spacing: 1px;
				line-height: 2em;
				color: $text-color;
			}

			a {
				@include hover_opacity();
			}
		}
	}

	// layout-b
	&--b {
		.c-loop--post__body {
			background:white;
		}
		img{
			width:100%;
			aspect-ratio: 2 / 1;
			object-fit: cover;
		}

		.c-loop--post__body__info {
			display: flex;
			flex-wrap: nowrap;
			@include mq-down(lg) {
				display: block;
			}

			.c-categories {
				min-width: 0;
				flex-grow: 1;

				a {
					font-size: 12px;
					font-weight: 700;
				}
			}

			.c-loop--post__date {
				width: 10.5em;
				text-align: right;
				@include mq-down(lg) {
					text-align: left;
				}

				&__full {
					font-size: 12px;
				}
			}
		}

		.c-loop--post--layout {
			margin: 0;

			p {
				font-size: 14px;
			}
		}

		.c-loop--post__title {
			margin: 0;
			font-size:20px;
			color:#3B4043;
			font-weight: bold;
			@include mq-down(md) {
				font-size:1.6rem;
			}
			a{
				color:#3B4043;
				font-weight: bold;
			}
		}

	}

	// layout-c
	&--c {
		border-bottom: 1px solid $line-color;
		padding: 40px 0;

		.c-loop--post__body__info {
			@include mq-up(sm) {
				display: flex;
				align-items: baseline;
				flex-wrap: nowrap;
			}

			.c-categories {
				min-width: 0;
				flex-grow: 1;

				a {
					font-size: 12px;
					font-weight: 700;
				}
			}

			.c-loop--post__date {
				flex-grow: 1;
				display: flex;
				flex-wrap: nowrap;
				align-items: center;

				@include mq-up(sm) {
					$c-loop--post__date___width: calc(10em + 40px);

					width: $c-loop--post__date___width;
					max-width: $c-loop--post__date___width;
					min-width: $c-loop--post__date___width;
					;
				}

				@include mq-down(sm) {
					margin: 20px 0;
				}


				&__full {
					margin: 0;
					font-size: 14px;
				}

				&:after {
					content: "";
					background: $line-color;
					height: 1px;
					width: 80px;
					margin: 0 24px;
					display: inline-block;
				}
			}
		}

		.c-loop--post__title {
			font-size: 16px;
			margin: 0;
			color:#3B4043;
		}
	}
}

.c-author{
	.c-author__name{
		padding-top:.5rem;
		font-weight: bold;
		font-size: 1.4rem;
	}
	
	.c-author__img{
		padding-right: 1.5rem;
		img{
			width:40px;
			height: 40px;
			object-fit: cover;
			border-radius: 25px;
		}
	}
}
