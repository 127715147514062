/*!------------------------------------*\
    Top
\*!------------------------------------*/
.js-gnav-nav {
	&--top {
		top: (0 - $gnav-top-nav-maxHeight) !important;
		left: 0;
		width: 100%;
		height: auto;
		max-height: $gnav-top-nav-maxHeight;
		transition: top $gnav-transitionDuration $gnav-transitionFunction;

		// アクティブ時
		&.is-active {
			top: 0 !important;
		}
	}
}
