.c-info__block {
    margin-bottom: 10rem;

    @include mq-down(md) {
        margin-bottom: 6rem;
    }

    .c-info__block__title {
        @include main-headline();
        margin-bottom: 5rem;

        @include mq-down(md) {
            margin-bottom: 2.5rem;
        }
    }

    .c-info__block__txt {
        @include sub-headline();
        line-height: 2;
        margin-bottom: 4rem;

        @include mq-down(md) {
            margin-bottom: 2rem;
        }
    }

    .c-info__block__img {
        margin-bottom: 10rem;

        @include mq-down(md) {
            margin-bottom: 50px;
        }

        img {
            width: 100%;
            aspect-ratio: 14 / 5;
            object-fit: cover;
        }
    }
}