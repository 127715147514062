.block__layout-01 {
    h2 {
        font-weight: bold;
    }

    .title__en {
        font-size: 1.8rem !important;
        font-weight: normal !important;

        @include mq-down(md) {
            font-size: 1.6rem !important;
        }
    }
}

.block__layout-02 {
    margin-bottom: 3rem;
}

.block__layout_2_text {
    p {
        line-height: 2;
        margin-top: 2rem;
    }
}

