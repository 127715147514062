.u-bg {
	&--post-full {
		background-size: cover !important;
		background-position: right !important;
		background-repeat: no-repeat !important;
	}

	&--main-color {
		background: $main;
	}

	&--main-pattern {
		background-repeat: no-repeat;
		background-size: cover;
	}

	&--position-top {
		object-position: center;
	}

	&--aspect {
		&-16-_-9 {
			aspect-ratio: 16 / 9;
		}

		&-4-_-3 {
			aspect-ratio: 4 / 3;
			width: 100%;
			height: 100%;
		}

		&-50-_-71 {
			aspect-ratio: 50 / 71;
			width: 100%;
			height: 100%;
		}

		&-cinema {
			aspect-ratio: 2.35 / 1;
			width: 100%;
			height: 100%;
		}
	}
}

.img__main {
	img{
		width:100%;
		height: auto;
		object-fit: cover;
		object-position: top;
	}
}

.img__sub{
	width:100%;
	height: 300px;
	@include mq-down(xl) {
		height:230px;
	}

	@include mq-down(lg) {
		height: 160px;
	}
}